import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import SubmitFeedback from 'job_wizard_mobile/components/steps/SubmitFeedback'

const HowMuchDidYouPay = {
  displayName: 'HowMuchDidYouPay',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion) {
    HowMuchDidYouPay.validator.validateField('value')
    HowMuchDidYouPay.validator.isValidField('value') && onStepProgression()
  },

  buttonLayout: function() {
    return 'none'
  },

  component: React.createClass({
    componentDidMount: function() {
      $('#job-modal').bootstrapValidator({})
      HowMuchDidYouPay.validator = $('#job-modal').data('bootstrapValidator')

      this.setupValidation()
    },

    setupValidation: function() {
      HowMuchDidYouPay.validator.addField('value',
        { validators: {
            notEmpty: {
              message: 'Please type your answer'
            },
            blank: {}
          }
        }
      )
    },

    handleSkipOnClick() {
      $('#job-modal').data('bootstrapValidator').removeField('value')
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', SubmitFeedback)
    },

    handleDoneOnClick() {
      data.closureSurvey.value = $('#value')[0].value
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', SubmitFeedback)
    },

    render: function() {
      return (
        <div>
          <h3 className="mb15 mt20">What was the final cost of the job?</h3>
          <div className="help-block pb24">
            This helps us get a better understanding of the average cost of jobs in this category and location.
          </div>
          <div className="form-group">
            <div className="input-group">
              <span className="input-group-addon">$</span>
              <input 
                  type="number" 
                  className="form-control" 
                  name="value"
                  placeholder="e.g. 200"
                  step=".01"
                  id="value"/>
            </div>
          </div>
          <div className="row pt30">
            <div className='col-sm-6 col-xs-6 pb12 pr4 pull-left'>
              <a href="#" className="btn btn-block btn-clear p12" onClick={this.handleSkipOnClick}>
                Skip
              </a>
            </div>
            <div className='col-sm-6 col-xs-6 pb12 pl4 pull-right'>
              <a href="#" className="btn btn-block btn-main p12" onClick={this.handleDoneOnClick}>
                Done
              </a>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default HowMuchDidYouPay