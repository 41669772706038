import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import SubmitConsumerLead from 'job_wizard_mobile/components/steps/SubmitConsumerLead'
import coreUtils from 'common/src/utils/coreUtils'

const ZipOffer = {
  component: React.createClass({
    componentDidMount: function(e) {
      actions.updateLastSlide(true)
    },
  	handleClick: function(e) {
      let option = $(e.currentTarget).data('value')

      actions.updateConsumerLeads({zip: option === 'interested' || ''})
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', SubmitConsumerLead)
		},
    render: function() {
      const imgThumb = {
        'maxWidth': '60px'
      }

      const clearBoth = {
        clear: 'both'
			}

			let get_it_done = ''

      if (coreUtils.mobilecheck()) {
        get_it_done = 'Get it done now with nothing <br />to pay today'
      } else {
        get_it_done = 'Get it done now with nothing to pay today'
      }

	    return (
	      <div>
	        <div className='pull-left mb30'>
	          <img style={imgThumb} src='/assets/partners/zipprimary.png' />
	        </div>

          <div style={clearBoth}></div>

		      <div className='font-21 bold'>Get the job done now. <br/> Pay later. Interest free.</div>

		      <a href='https://zip.co/serviceseeking' className='hidden-sm blue-text pl0 no-border bg-transparent mt30 font-18' target='_blank'>Learn more</a>

		      <div className='mt10 mb20 bg-gray-2 radius-6 p16 border mb20'>
		      	<div className='icon-parag icon-parag-16-v2 mb8'>
		      		<i className='ficon-tick text-blue' />
		      		<div className='text font-16' dangerouslySetInnerHTML={{__html: get_it_done}} />
		      	</div>
		      	<div className='icon-parag icon-parag-16-v2 mb8'>
		      		<i className='ficon-tick text-blue' />
		      		<div className='text font-16'>
		      			Pay back over time, interest free
		      		</div>
		      	</div>
		      	<div className='icon-parag icon-parag-16-v2'>
		      		<i className='ficon-tick text-blue' />
		      		<div className='text font-16'>
							  Use your Zip account at thousands of other retailers
		      		</div>
		      	</div>
		      </div>

          <div className="row">
            <div className="col-xs-5 pr0">
              <button data-value='not-interested' onClick={this.handleClick} className='btn btn-gray-1 btn-clear radius-6 btn-block'>No thanks</button>
            </div>
            <div className="col-xs-7 pl8">
              <button data-value='interested' onClick={this.handleClick} className='btn btn-secondary radius-6 btn-block'>I'm interested</button>
            </div>
          </div>
	      </div>
	    )
    }
  })
}

export default ZipOffer
