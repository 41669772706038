import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import coreUtils from 'common/src/utils/coreUtils'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import SelectLocation from 'job_wizard_mobile/components/steps/SelectLocation'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'

const EnterDescription = {
  validator: '',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    } else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('.enter-description').addClass('hidden')
    $('#job-modal').data('bootstrapValidator').removeField('description')

    JobWizardEventEmitter.emit('job:wizard:advanceToStep', SelectLocation)
  },

  component: React.createClass({

    getInitialState: function () {
      return {
        description: data.description
      }
    },

    componentDidMount: function() {
      this.setupValidation()
      this.descriptionCounter()
    },

    MIN_TEXT_LENGTH: 20,

    setupValidation: function (minTextLength = this.MIN_TEXT_LENGTH) {
      this.validator = $('#job-modal').data('bootstrapValidator')

      this.validator.addField(
        'description', {
          trigger: 'blur',
          validators: {
            callback: {
              callback: function (value, validator, $field) {
                if ($field.val().length < 20) {
                  $('#char_count').css('color', 'red')
                  $('#char_count_message').css('color', 'red')

                  return {
                    valid: false, message: ''
                  }
                }

                return { valid: true, message: '' }
              }
            }
          }
        },
      )

      this.validator.$form.on('error.field.bv', function(e, data) {
        data.element
          .data('bv.messages')
          .find('.help-block[data-bv-for="' + 'description' + '"]').hide();
      })
    },

    descriptionChanged: function(e) {
      actions.updateDescription(e.target.value)
    },

    revalidateIfMinTextLengthReached: function(e) {
      e.target.scrollIntoView()
      e.target.style.height = "100px";
      e.target.style.height = `${e.target.scrollHeight}px`

      const revalidateDescriptionField = () => { //revalidating removes error message once char limit is reached
        this.validator.$form.data('bootstrapValidator').revalidateField('description')
      }

      if (e.target.value.length === this.MIN_TEXT_LENGTH) {
        revalidateDescriptionField()
      }
    },

    descriptionCounter: function () {
      $('#description').on('keyup', function (e) {
        var $description        = $('#description')
        var desc_length         = $description.val().length
        var remaining_chars     = 20 - desc_length >= 0 ? 20 - desc_length : 0
        var $char_count         = $('#char_count')
        var $char_count_message = $('#char_count_message')

        if (desc_length >= 20) {
          $char_count.css('color', 'gray')
          $char_count_message.css('color', 'gray')
        }

        $char_count.html(`(${remaining_chars})`)

      })
    },

    render: function() {
      return(
        <div>
          <div className="enter-description form-group sm-pt0">
            <h3 className="mb5 mt10">Quickly describe the job you need done.</h3>
            <i id="char_count_message" style={{color: 'gray'}}>Must be at least 20 characters</i>
            <i id="char_count" style={{color: 'gray'}} className="pull-right">(20)</i>
            <textarea
              autoFocus
              className="component-block mt15 p16 h120 form-control text-desc-mobile"
              id="description"
              name="description"
              style={{overflow:'scroll', minHeight: '100px', maxHeight: '170px'}}
              defaultValue={this.state.description}
              onChange={this.descriptionChanged}
              onKeyUp={this.revalidateIfMinTextLengthReached}
            />
            <span className="pull-right mt20">
              <NavigationButtons
                navButtonLayout='okButton'
                onStepProgression={this.props.onStepProgression}
              />
            </span>
          </div>
        </div>
      )
    }
  })
}
 export default EnterDescription
