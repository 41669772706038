import React from 'react'
import coreUtils from 'common/src/utils/coreUtils'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import AddAttachment from 'job_wizard_mobile/components/steps/AddAttachment'

const GetContactPreference = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    onStepProgression()
  },

  onLeftNavButtonClick(onStepProgression) {
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', AddAttachment)
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        contactPreference: data.contactPreference,
        showSuppressants: false
      }
    },

    componentDidMount: function() {
      let job = data;
      var subcats = []

      for (var j = 0; j < job.categories.length; j++) {
        subcats.push(job.categories[j].id)
      }

      $.ajax({
          url: '/jobs/check_suppressant_eligibility',
          dataType: 'json',
          method: 'get',
          data: {
            subcat_ids: subcats,
            suburb_id: data.suburb_id,
            can_call: data.canCall
          }
      })
      .done((response) => {
        actions.updateQuoteEstimate(response.max_quote_estimate)
        if (response.show_suppressants === true) {
          this.setState({showSuppressants: true})
        }
        else {
          this.props.onStepProgression()
        }
      })
    },

    updateContactPreference: function(e) {
      let value = $(e.currentTarget).find('[type="radio"]')[0].value
      let contactPreference = (value === 'all') ? null : value

      actions.updateContactPreference(contactPreference)
      this.setState({ contactPreference: contactPreference })
      this.props.onStepProgression()
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden  = `${radioButtonVisible} hidden`

      return (
        <div>
          {
            this.state.showSuppressants === true &&
            <div className="contact-preference">
              <h3 className="mb8 mt24">Who should receive your job?</h3>
              <h4 className="mb20 text-copy-1 font-16"><i>We expect up to {data.quoteEstimate} businesses to call you about this job.</i></h4>
              <div className="form-group">
                <div className="radio-button-box minor-bold">
                  <div className='radio' onClick={this.updateContactPreference}>
                    <div>
                      <div className="text">
                        <strong>All businesses</strong>
                        <p className="mb0">Businesses that are interested in the job can call.</p>
                      </div>
                      <input type="radio" defaultChecked={(this.state.contactPreference === "all") ? 'checked' : ''}
                             id="contact-preference-all" name="contact-preference" value="all" className="form-control invisible" />
                    </div>
                  </div>
                  <div className={`radio ${(this.state.contactPreference === "pro_and_top_rated") ? "active" : ""}`} onClick={this.updateContactPreference}>
                    <div>
                      <div className="text">
                        <strong>Insured & Top Rated Businesses</strong>
                        <p className="mb0">We have a copy of their insurance or they have at least 4.5/5 Stars from 5 or more reviews</p>
                      </div>
                      <input type="radio" defaultChecked={(this.state.contactPreference === "pro_and_top_rated") ? 'checked' : ''}
                             id="contact-preference-pro-and-top-rated" name="contact-preference" value="pro_and_top_rated" className="form-control invisible" />
                    </div>
                  </div>
                  <div className="mt16 mb30">
                    <div className="text-copy-1">
                      <p><b>Did you know?</b> Customers who choose Insured or Top Rated here are up to 50% more likely to end up finding a business they love.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      )
    }
  })
}

export default GetContactPreference
