import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'
import HasJobBeenDoneYet from 'job_wizard_mobile/components/steps/HasJobBeenDoneYet'
import coreUtils from 'common/src/utils/coreUtils'
import API from 'common/src/api'

const JobUpgradePosted = {
  validator: '',
  component: React.createClass({
    getInitialState: function() {
      return {
        hasJobs: false
      }
    },

    componentDidMount: function() {
      this.fetchJobClosureData()
    },

    fetchJobClosureData: function() {
      let job_closure_data = API.V2.jobClosureData()

      $.ajax(job_closure_data).done((data) => {
        if(typeof data.job !== 'undefined'){
          this.setState({hasJobs: true})

          let survery_data = {
            job: data.job,
            possibleBusinesses: data.possible_businesses,
            outcome: data.outcome,
            providerAccountId: data.provider_account ? data.provider_account.id : null,
            providerAccountName: data.provider_account ? data.provider_account.business_name: null,
            rating: null,
            comments: null,
            selectedReasons: [],
            value: data.amountPaid
          }

          actions.updateClosureSurvey(survery_data)
        }
      }).fail((data) => { document.location = '/500' })
    },

    nextStep: function() {
      let jobID = data.id
      let message = JSON.stringify({ message_type: 'jobs_wizard', message: 'JOB_POSTING_COMPLETED', payload: jobID })

      if(this.state.hasJobs) {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', HasJobBeenDoneYet)
      }
      else {
        $('#job-modal').modal('hide')

        if (MobileChannelHandler.sendChannelMessage(message)) {
          return;
        }

        document.location.href = redirectUrl()

        function redirectUrl() {
          let jobID = data.id

          switch (data.referrer.cta) {
            case 'profile': {
              return `/jobs/${jobID}/profilejob?successful_job=true`
            }
            case 'business_directory_card': {
              return `/jobs/${jobID}/bcardjob?successful_job=true`
            }
            default: {
              return `/jobs/${jobID}/posted?successful_job=true`
            }
          }
        }
      }
    },

    render: function() {
      return (
        <div>
          <div className='relative border radius-16'>
            <div>
              <img className="absolute width-100" src="/assets/job_page/wave.svg"/>
            </div>
            <div className='text-center pt64 mt32'>
              <div className='bold fs20 mb8'>Your Premium Job is now Posted</div>
              <div className='mb12'>
                <svg className='text-blue' width='50px' height='50px' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'>
                  <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M1 5.917 5.724 10.5 15 1.5'/>
                </svg>
              </div>
              <div className='bold uppercase mb8 fs18'>What's Next?</div>
              <div className='fs16 p20'>
                Your job will be sent by SMS and will appear at the top of the list for all active matched businesses. Interested businesses will contact you soon.
              </div>
              <div className='fs16 p20'>
                While you're waiting, is there anything else you need done?
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                { data.popularJobs.map((item, key) =>
                  <a href={`/jobs/new?subcategory-id=${item.subcategory.id}&subcategory=${item.subcategory.name}`} target='_blank' key={key} style={{ width: '33.33%'}}>
                    <img src={item.icon} className="border radius-16 m4"/>
                  </a>
                )}
                <a href='/jobs/new' style={{width: '33.33%'}} target='_blank'><img src="/assets/mrs_post any job.png" className="border radius-16 m4" target='_blank' height='92%' width='90%'/></a>
              </div>
            </div>
          </div>
          <button className='btn btn-block btn-main radius-16 fs18 p16 mt20 mb20' onClick={this.nextStep}>Close</button>
        </div>
      )
    }
  })
}

export default JobUpgradePosted
