import SelectCategory from 'job_wizard_mobile/components/steps/SelectCategory'
import SelectLocation from 'job_wizard_mobile/components/steps/SelectLocation'
import EnterEmailAddress from 'job_wizard_mobile/components/steps/EnterEmailAddress'
import LoginOmniauth from 'job_wizard_mobile/components/steps/LoginOmniauth'
import EnterPassword from 'job_wizard_mobile/components/steps/EnterPassword'
import GetFirstName from 'job_wizard_mobile/components/steps/GetFirstName'
import GetContact from 'job_wizard_mobile/components/steps/GetContact'
import VerifyNumber from 'job_wizard_mobile/components/steps/VerifyNumber'
import CanCall from 'job_wizard_mobile/components/steps/CanCall'
import EnterDescription from 'job_wizard_mobile/components/steps/EnterDescription'
import GetContactPreference from 'job_wizard_mobile/components/steps/GetContactPreference'
import AddAttachment from 'job_wizard_mobile/components/steps/AddAttachment'
import ChooseSchedule from 'job_wizard_mobile/components/steps/ChooseSchedule'
import ChooseBudget from 'job_wizard_mobile/components/steps/ChooseBudget'
import BudgetPrice from 'job_wizard_mobile/components/steps/BudgetPrice'
import ChooseSiteVisit from 'job_wizard_mobile/components/steps/ChooseSiteVisit'
import SiteVisitTime from 'job_wizard_mobile/components/steps/SiteVisitTime'
import CheckJobDuplicate from 'job_wizard_mobile/components/steps/CheckJobDuplicate'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'
import SubmitJob from 'job_wizard_mobile/components/steps/SubmitJob'
import ZipOffer from 'job_wizard_mobile/components/steps/ZipOffer'
import HasJobBeenDoneYet from 'job_wizard_mobile/components/steps/HasJobBeenDoneYet'
import WhoDidTheJob from 'job_wizard_mobile/components/steps/WhoDidTheJob'
import RateExperience from 'job_wizard_mobile/components/steps/RateExperience'
import HowMuchDidYouPay from 'job_wizard_mobile/components/steps/HowMuchDidYouPay'
import PostJobAgain from 'job_wizard_mobile/components/steps/PostJobAgain'
import JobUpgrade from 'job_wizard_mobile/components/steps/JobUpgrade'
import PurchaseJobUpgrade from 'job_wizard_mobile/components/steps/PurchaseJobUpgrade'
import JobUpgradePosted from 'job_wizard_mobile/components/steps/JobUpgradePosted'
import coreUtils from 'common/src/utils/coreUtils'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'

const StepSequence = {
  steps: [],

  defaultSequence() {

    this.steps = [
      SelectCategory,
      SelectLocation,
      EnterDescription,
      ...loginSteps(),
      ...displayContactStep(),
      VerifyNumber,
      ...displayAddAttachment(),
      GetContactPreference,
      ChooseSchedule,
      ChooseBudget,
      BudgetPrice,
      ChooseSiteVisit,
      SiteVisitTime,
      JobSummary,
      CheckJobDuplicate,
      SubmitJob,
      JobUpgrade,
      PurchaseJobUpgrade,
      JobUpgradePosted,
      HasJobBeenDoneYet,
      WhoDidTheJob,
      RateExperience,
      HowMuchDidYouPay,
      PostJobAgain
      //ZipOffer  ////////// Temporary. Please check this ticket: https://serviceseeking.atlassian.net/browse/SALES-1703
    ]

    function displayAddAttachment() {
      return MobileChannelHandler.hasMobileChannel() ? [] : [ AddAttachment ]
    }

    function loginSteps() {
      return coreUtils.userLoggedIn() ? [] : [ EnterEmailAddress, EnterPassword, GetFirstName, GetContact ]
    }

    function displayContactStep() {
      return (coreUtils.userLoggedIn() && coreUtils.userHasValidPhone()) ? [] : [ CanCall ]
    }

    return this.steps
  },

  init() {
    this.defaultSequence();
  },

  getSteps() {
    return this.steps;
  }
}

export default StepSequence
