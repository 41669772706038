import React from 'react'
import actions from 'common/components/job_wizard/data/actions'
import data from 'common/components/job_wizard/data/data'
import NavigationButtons from 'common/components/NavigationButtons'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import postingProgressStore from 'common/components/job_wizard/data/posting_progress/store'
import EnterEmailAddress from 'job_wizard_mobile/components/steps/EnterEmailAddress'
import EnterPassword from 'job_wizard_mobile/components/steps/EnterPassword'
import CanCall from 'job_wizard_mobile/components/steps/CanCall'

const LoginOmniauth = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    onStepProgression()
  },

  buttonLayout: function() {
    return 'upButton'
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        loading: false,
        errorMessage: ''
      }
    },

    componentDidMount() {
      if (data.omniAuthDetails['account_from_omni_auth'] === true) {
        window.fbAsyncInit = function() {
          FB.init({appId: '482010146568223',
                   cookie: true,
                   version: 'v2.2',
                   autoLogAppEvents : true,
                   xfbml: true,
                   status: true});
        };
        (function(d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "//connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        $.ajax({
          url: 'https://apis.google.com/js/client:plus.js?onload=gpAsyncInit',
          dataType: 'script',
          cache: true
        })
        window.gpAsyncInit = function() {
        }
      }
      else {
        props.onStepProgression()
      }
    },

    handleGoogleLogin(e) {
      let thisComponent = this
      let jobData = data

      if (e.target.checked) {
        this.setState({loading: true})
        gapi.auth2.authorize({
          response_type: 'code',
          client_id: '1044201461425-0kma4id2dmnmspp65el6223n7k3hr6l2.apps.googleusercontent.com',
          scope: 'email profile'
        }, (response) => {
          if (response && !response.error) {
            jQuery.ajax({
              type: 'POST',
              url: '/users/auth/google_oauth2/callback?origin=job_modal',
              dataType: 'json',
              data: response
            }).done((result) => {
              actions.updateOmniAuthDetails(result)
              actions.updateEmailAddress(result.email)
              thisComponent.autoProgress(jobData)
            }).fail((result) => {
              this.setState({ errorMessage: result.responseJSON.error })
            })
          }
        })
      }
      this.setState({loading: false})
    },

    handleFacebookLogin(e) {
      let thisComponent = this
      let jobData = data

      if (e.target.checked) {
        this.setState({loading: true})
        FB.login((response) => {
          if (response.authResponse) {
            jQuery.ajax({
              crossDomain: true,
              url: '/users/auth/facebook/callback?origin=job_modal',
              type: 'POST',
              data: response,
              headers: { 'Access-Control-Allow-Origin': '*' }
            }).done((result) => {
              actions.updateOmniAuthDetails(result)
              actions.updateEmailAddress(result.email)
              thisComponent.autoProgress(jobData)
            }).fail((result) => {
              this.setState({ errorMessage: result.responseJSON.error })
            })
          }
        }, {scope: 'email'});
      }
      this.setState({loading: false})
    },

    autoProgress(data) {
      if (data.omniAuthDetails['oauth_email_exists']) {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', EnterPassword)
      }
      else if (data.omniAuthDetails['account_from_omni_auth'] === true) {
        postingProgressStore.updateTracking({ UserLoggedIn: true })
        window.App.currentUserId = data.omniAuthDetails["user_id"]
        window.App.currentUserName = data.omniAuthDetails["user_name"]
        window.App.currentUserStatus = data.omniAuthDetails["user_status"]
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', CanCall)
      }
      else {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', EnterEmailAddress)
      }
    },

    render() {
      return (
        <div className="other-login p30 pt0 pb40">
          <div className="checkbox">
            <label>
              <input type="checkbox" name="newsletter" onChange={data.omniAuthProvider === 'Google' ? this.handleGoogleLogin : this.handleFacebookLogin}/>
              Opt in to receive (via SMS & email) special offers, news and the latest updates
              from ServiceSeeking.com.au
              <br/><br/>
              By ticking off the checkbox, you agree to receive quotes via email & SMS as well as marketing messages and to our <a className='no-wrap' href='https://www.serviceseeking.com.au/blog/terms-and-conditions/' target='_blank'>Terms & Conditions</a>
              <br/><br/>
              Please opt-in to continue
            </label>
            {
              this.state.errorMessage &&
              <div className="pt20 text-center">
                <span className="help-block has-error text-red">
                  { this.state.errorMessage }
                </span>
              </div>
            }
            {
              this.state.loading == true &&
              <div className="text-center">
                <span className="spinner-icon "/>
              </div>
            }
          </div>
        </div>
      )
    }
  })
}
export default LoginOmniauth
