import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import ErrorMessage from 'common/components/ErrorMessage'
import coreUtils from 'common/src/utils/coreUtils'
import NavigationButtons from 'common/components/NavigationButtons'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import EnterEmailAddress from 'job_wizard_mobile/components/steps/EnterEmailAddress'
import CanCall from 'job_wizard_mobile/components/steps/CanCall'
import AddAttachment from 'job_wizard_mobile/components/steps/AddAttachment'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'
import VerifyNumber from 'job_wizard_mobile/components/steps/VerifyNumber'

const GetFirstName = {
  validator: '',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('#job-modal').data('bootstrapValidator').removeField('first_name')
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', EnterEmailAddress)
  },

  component: React.createClass({
    componentDidMount: function() {
      if (window.App.currentUserId !== null && typeof window.App.currentUserId !== "undefined") {
        if (coreUtils.userHasValidPhone()) {
          JobWizardEventEmitter.emit('job:wizard:advanceToStep', VerifyNumber)
        }
        else {
          JobWizardEventEmitter.emit('job:wizard:advanceToStep', CanCall)
        }
      }

      $('#job-modal').bootstrapValidator({ /* */ })
      GetFirstName.validator = $('#job-modal').data('bootstrapValidator')
      this.setupValidation()
    },

    setupValidation: function () {
      GetFirstName.validator.addField('first_name', {
        validators: {
          notEmpty: {
            message: 'Please enter your first name'
          },
          blank: {}
        }
      })
    },

    handleFirstNameChange: function(e) {
      actions.updateFirstName(e.target.value)
    },

    render() {
      return (
        <div>
          <ErrorMessage />
          <h3 className="mb15 mt20">Can we get your first name?</h3>
          <div className="form-group">
            <div className="input-group p5 border radius-6 details-mobile">
              <input type="text" 
                     className="form-control" 
                     onChange={this.handleFirstNameChange} 
                     id="first_name"
                     name="first_name"
                     defaultValue={data.firstName}
              />
              <span className="input-group-btn">
                 <NavigationButtons
                  navButtonLayout='okButton'
                  onStepProgression={this.props.onStepProgression}
                /> 
              </span>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default GetFirstName
