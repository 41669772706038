import 'common/index'
import 'home/src/socialProof'
import 'home/src/compareToOthers'
import 'home/styles/index.scss'
import GetQuotes from 'common/components/job_wizard/GetQuotes'
import ActivityFeed from 'home/components/index/ActivityFeed'
import OnPageInlineEditor from 'common/components/OnPageInlineEditor'
import React from 'react'
import ReactDOM from 'react-dom'

document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.getElementsByClassName('get_quotes')

  for (let i = 0; i < nodes.length; i++) {
    const data = nodes[i].getAttribute('data').replace(/&quot;/g, '"')

    ReactDOM.render( < GetQuotes { ...data } />, nodes[i])
  }

  const activityFeedsNode = document.getElementById('activity-feeds')
  const activityFeedData = JSON.parse(activityFeedsNode.getAttribute('data').replace(/&quot;/g, '"'))

  if (activityFeedsNode && activityFeedData) {
    ReactDOM.render( < ActivityFeed { ...activityFeedData } />, activityFeedsNode)
  }

  const statNodes = document.getElementsByClassName('homepage-stats')


  for (let i = 0; i < statNodes.length; i++) {
    const data = JSON.parse(statNodes[i].getAttribute('data').replace(/&quot;/g, '"'))

    ReactDOM.render( < OnPageInlineEditor { ...data } />, statNodes[i])
  }
})

$(function () {
  window.$.fn.extend({
    addHidden() {
      return this.addClass('hidden');
    },

    rmHidden: function () {
      return this.removeClass('hidden');
    }
  })
});
