import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import NavigationButtons from 'common/components/NavigationButtons'
import postingProgressStore from 'common/components/job_wizard/data/posting_progress/store'
import StepSequence from 'job_wizard_mobile/components/StepSequence'
import coreUtils from 'common/src/utils/coreUtils'

import API from 'common/src/api'

const EnterPassword = {
  validator: '',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    let passwordCheck = () => {
      var sessionObj = API.V2.userSession({
        email: data.emailAddress,
        password: $('#password').val(),
        reauth: true
      })

      $.ajax(sessionObj)
        .done((result) => {
          postingProgressStore.updateTracking({ UserLoggedIn: true })
          if (data.omniAuthDetails['oauth_email_exists']) {
            attachOmniAuthToUser()
          }

          $('#user-session-id').attr('title', result.data.id)
          window.App.currentUserId = result.data.id
          window.App.userPhoneIsValid = result.meta['valid-phone']
          window.App.currentUserStatus = result.data.attributes.status

          onStepProgression()
        })
        .fail((xhr, textStatus, errorThrown) => {
          if (xhr.status === 401 || xhr.status === 422) {
            EnterPassword.validator.resetForm()
            EnterPassword.validator.updateMessage('password', 'blank', 'Invalid Password: Please verify your password and try again.')
            EnterPassword.validator.updateStatus('password', 'INVALID', 'blank')
          } else {
            document.location.href = '/500'
          }
        })

      function attachOmniAuthToUser(){
        $.ajax({
          url: '/users/auth/attach_oauth_uid',
          dataType: 'json',
          method: 'POST',
          data: {
                  user_id: data.omniAuthDetails['user_id'],
                  oauth_uid: data.omniAuthDetails['oauth_uid'],
                  oauth_provider: data.omniAuthDetails['oauth_provider'],
                }
        })
      }
    }

    let newPasswordCheck = () => {
      let $newPassword = $("#new-password").val()

      if ($newPassword !== '' && $newPassword !== null) {
        $.ajax({
          url: '/api/users/update_password',
          dataType: 'json',
          data: { email: data.emailAddress,
                  otp: data.otp,
                  password: $newPassword,
                  password_confirmation: $newPassword
                }
        }).done((data) => {
          if (data.result) {
            postingProgressStore.updateTracking({ UserLoggedIn: true })
            $('#user-session-id').attr('title', data.session_id)
            window.App.currentUserId = data.user_id

            onStepProgression()
          }
          else {
            document.location.href = '/500'
          }
        })
      }
      else {
        $('.password-error').rmHidden()
      }
    }
  
    if (data.mustCreateAccount === true){
      onStepProgression()
    }
    else {
      if (data.otp !== null && data.otp !== '') {
        newPasswordCheck()
      }
      else{
        passwordCheck()
      }
    }
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        showPasswordField: false,
        showPassword: false,
        passwordless: null,
        showSendResetPassword: false,
        loadingSms: false,
        loadingEmail: false,
        alertMessage: '',
        messageClass: '',
        otp: null,
        validMobileNumber: false
      }
    },

    componentDidMount() {
      $('#job-modal').bootstrapValidator({ /* */ })
      EnterPassword.validator = $('#job-modal').data('bootstrapValidator')

      if (data.mustCreateAccount == false) {
        this.setState({ showPasswordField: true })
        this.checkIfPasswordless()
      } else if (data.mustCreateAccount == true) {
        this.props.onStepProgression()
      }
    },

    setupValidation() {
      EnterPassword.validator.addField("password", {
        validators: {
          notEmpty: {
            message: "Please enter your password"
          },
          blank: {}
        }
      })
    },

    checkIfPasswordless() {
      $.ajax({
        url: '/api/users/check_passwordless',
        dataType: 'json',
        data: { email: data.emailAddress }
      }).fail(function () {
        document.location = "/500"
      }).done((data) => {
        if (data.passwordless === true) {
          this.setState({ passwordless: true,
                          validMobileNumber: data.valid_mobile_number })
        }
        else {
          this.setState({ passwordless: false,
                          validMobileNumber: data.valid_mobile_number })
        }
        this.setupValidation()
      })
    },

    handleOnclickResetPassword() {
      this.setState({ passwordless: null, showSendResetPassword: true })
    },

    handleSendEmail() {
      $.ajax({
        beforeSend: () => {
          this.setState({ loadingEmail: true })
        },
        url: '/api/users/send_otp_email',
        dataType: 'json',
        data: { email: data.emailAddress }
      }).done((data) => {
        this.setState({ alertMessage: 'Please check your email, we have sent you the code to create new password.',
                        messageClass: 'alert-success',
                        loadingEmail: false,
                        showSendResetPassword: false,
                        showOtpField: true,
                        passwordless: null
                      })
      })
    },

    handleSendSms() {
      $.ajax({
        beforeSend: () => {
          this.setState({ loadingSms: true })
        },
        url: '/api/users/send_otp_sms',
        dataType: 'json',
        data: { email: data.emailAddress }
      }).done((data) => {
        this.setState({ alertMessage: 'Please check your SMS, we have sent you the code to create new password.',
                        messageClass: 'alert-success',
                        loadingSms: false,
                        showSendResetPassword: false,
                        showOtpField: true,
                        passwordless: null
                      })
      })
    },

    handleOnclickSubmitCode() {
      let otp = $('#otp').val()
      $.ajax({
        beforeSend: () => {
          this.setState({ loadingSubmit: true })
        },
        url: '/api/users/check_otp',
        dataType: 'json',
        data: { email: data.emailAddress, otp: otp }
      }).done((data) => {
        if (data.result == true) {
          this.setState({ showOtpMessageError: false,
                          showCreatePassword: true,
                          showOtpField: false,
                          alertMessage: ''
                        })
          actions.updateOtp(otp)
        }
        else {
          this.setState({ showOtpMessageError: true })
        }
      })
    },

    handleShowPassword() {
      this.setState({ showPassword: !this.state.showPassword })
    },

    renderSendEmailOption() {
      return (
        <button
          className="btn btn-block btn-clear radius-6"
          onClick={this.handleSendEmail}>{this.state.loadingEmail ? <span className="spinner-icon "/> : 'Send code via email'}
        </button>
      )
    },

    renderSendSmsOption() {
      if (data.phoneNumber !== null && this.state.validMobileNumber) {
        return (
          <span>
            <p className="mt10">or</p>
            <p className="mb10 mt10">Send an SMS to {data.phoneNumber}</p>
            <button
              className="btn btn-block btn-clear radius-6"
              onClick={this.handleSendSms}>{this.state.loadingSms ? <span className="spinner-icon "/> : 'Send code via SMS'}
            </button>
          </span>
        )
      }
    },

    renderAlertMessage() {
      if (this.state.alertMessage !== '') {
        return (
          <div className={`alert ${this.state.messageClass}`}>{this.state.alertMessage}</div>
        )
      }
    },

    renderOtpField() {
      if (this.state.showOtpField) {
        return (
          <div>
            <h3 className="mb15 mt20">Enter code:</h3>
            <div className="input-group">
              <span>
                <input
                  type="text"
                  name="otp"
                  id="otp"
                  className="form-control"
                />
              </span>
              <span className="input-group-btn">
                <a href="#" className="btn btn-block btn-secondary" onClick={this.handleOnclickSubmitCode}>Submit</a>
              </span>
            </div>
            { this.state.showOtpMessageError &&
              <div className="help-block text-red">Wrong code.</div>
            }
          </div>
        )
      }
    },

    renderCreatePasswordField() {
      if (this.state.showCreatePassword) {
        return (
          <div>
            <h3 className="mb15 mt20">Create new password</h3>
            <div className="form-group">
              <div className="input-group p5 border radius-6 details-mobile">
                <span>
                  <input
                    type={this.state.showPassword ? `text` : `password`}
                    name="new-password"
                    id="new-password"
                    className="form-control"
                  />
                </span>
                <span className="input-group-btn">
                  <button
                    className={`btn btn-clear no-border p0 ` + (this.state.showPassword ? 'ficon-no-views fs24' : 'ficon-views_icon')}
                    type="button"
                    onClick={this.handleShowPassword}>
                  </button>
                </span>
                <span className="pl10 input-group-btn">
                  <NavigationButtons
                    navButtonLayout='okButton'
                    onStepProgression={this.props.onStepProgression}
                  />
                </span>
              </div>
              <span className="password-error help-block text-red hidden">Please enter your new password.</span>
            </div>
          </div>
        )
      }
    },

    renderPasswordlessMessage() {
      if (this.state.passwordless === true) {
        return (
          <span>
            You haven't set up a password for this account yet.
            <a href="#" onClick={this.handleOnclickResetPassword}> Get a code to create new password</a>
          </span>
        )
      }
    },

    renderExistingPasswordMessage() {
      if (this.state.passwordless === false) {
        return (
          <span>
            We've found your existing account! Please enter your password to login and proceed.
          </span>
        )
      }
    },

    renderSendCodeOptions() {
      if (this.state.showSendResetPassword) {
        return (
          <p className="p10 mt10 text-center">
            {this.renderSendEmailOption()}
            {this.renderSendSmsOption()}
          </p>
        )
      }
    },

    renderPasswordField() {
      if (this.state.passwordless === false) {
        return (
          <div>
            <h3 className="mb15 mt20">Password</h3>
            <div className="form-group">
              <div className="input-group p5 border radius-6 details-mobile">
                <span>
                  <input
                    type={this.state.showPassword ? `text` : `password`}
                    name="password"
                    id="password"
                    placeholder="Password"
                    className="form-control"
                  />
                </span>
                <span className="input-group-btn">
                  <button
                    className={`btn btn-clear no-border p0 ` + (this.state.showPassword ? 'ficon-no-views fs24' : 'ficon-views_icon')}
                    type="button"
                    onClick={this.handleShowPassword}>
                  </button>
                </span>
                <span className="pl10 input-group-btn">
                  <NavigationButtons
                    navButtonLayout='okButton'
                    onStepProgression={this.props.onStepProgression}
                  />
                </span>
              </div>
            </div>
          </div>
        )
      }
    },

    renderResetPasswordButton() {
      if (this.state.passwordless === false) {
        return (
          <a href="#"
            className="pull-right mt5"
            onClick={this.handleOnclickResetPassword}>Reset your password
          </a>
        )
      }
    },

    render() {
      return ( 
        <div>
          {
            this.state.showPasswordField === true &&
            <div className="form-group">
              {this.renderAlertMessage()}
              { this.renderOtpField() }
              { this.renderCreatePasswordField() }
              <div className={`grey-box p10 mt10 ${this.state.passwordless !== null ? '' : 'hidden'}`}>
                { this.renderPasswordlessMessage() }
                { this.renderExistingPasswordMessage() }
              </div>
              { this.renderSendCodeOptions() }
              { this.renderPasswordField() }
              { this.renderResetPasswordButton() }
            </div>
          }     
        </div>
      )
    }
  })
}
export default EnterPassword
