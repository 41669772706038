import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import coreUtils from 'common/src/utils/coreUtils'
import stringUtils from 'common/src/utils/stringUtils'
import store from 'common/components/job_wizard/data/posting_progress/store'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import HasJobBeenDoneYet from 'job_wizard_mobile/components/steps/HasJobBeenDoneYet'
import JobUpgrade from 'job_wizard_mobile/components/steps/JobUpgrade'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'

import API from 'common/src/api'

import Cookies from 'js-cookie'

const SubmitJob = {
  displayName: 'SubmitJob',
  errorMessage: '',

  component: React.createClass({
    getInitialState: function() {
      return {
        hasJobs: false
      }
    },

    componentDidMount: function() {
      this.createJob()
    },

    createJob: function() {
      let job = data;

      const $modalFooter = $('#job-modal').find('.modal-footer')

      $modalFooter.hide()

      var subcats = []
      for (var j = 0; j < job.categories.length; j++) {
        subcats[j] = { type: 'subcategories',
                       id: job.categories[j].id }
      }

      var jobCreateData = {
        data: {
          type: 'jobs',
          attributes: {
            description: buildDescription(),
            starting:    job.schedule.replace(/_/g, ' '),
            budget:      job.minBudget,
            min_budget:  job.minBudget,
            max_budget:  job.maxBudget,
            can_call:    job.canCall,
            schedule:    buildJobSchedule(),
            preferred_outcome: job.preferredOutcome,
            interested_in_best_pro: job.interestedInBestPro,
            contact_preference: job.contactPreference,
            disable_processing: false
          },
          relationships: {
            suburb: {
              data: {
                type: 'suburbs',
                id: job.suburb_id
              }
            },
            'job-subcategories': {
              data: subcats
            },
            'exclusive-to-business': {
              data: {
                type: 'businesses',
                id: data.exclusiveFor
              }
            }
          }
        },
        'included': [
          {
            'type': 'alliance-partners',
            'attributes': { 'name': job.originName }
          },
          {
            'type': 'job-referrers',
            'attributes': data.referrer
          },
          {
            'type': 'tracking-data',
            'attributes': {
              'creative':     decodeURI(Cookies.get('creative')),
              'keyword':      decodeURI(Cookies.get('keyword')),
              'matchtype':    decodeURI(Cookies.get('matchtype')),
              'adposition':   decodeURI(Cookies.get('adposition')),
              'campaignid':   decodeURI(Cookies.get('campaignid')),
              'adgroupid':    decodeURI(Cookies.get('adgroupid')),
              'geo':          decodeURI(Cookies.get('geo')),
              'utm_campaign': decodeURI(Cookies.get('utm_campaign')),
              'utm_source':   decodeURI(Cookies.get('utm_source')),
              'utm_medium':   decodeURI(Cookies.get('utm_medium')),
              'utm_content':  decodeURI(Cookies.get('utm_content')),
              'gclid':        decodeURI(Cookies.get('gclid'))
            }
          }
        ]
      }

      $.ajax(API.V2.jobCreate(jobCreateData))
        .done((createdJson) => {
          store.updateTracking({ jobId: createdJson.data.id })
          data.id = createdJson.data.id
          for (var k = 0; k < (createdJson.included || []).length; k++) {
            if (createdJson.included[k].type === 'credit-cards') {
              data.with_cc   = true
              data.cc_number = createdJson.included[k].attributes.number
            }
          }
          coreUtils.appMsg({ id: createdJson.data.id }) // used by mobile app for controlling the flow
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: 'custom.event.submitJob' });
          coreUtils.googleAnalytics({hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobpostfinal'})

          if(job.attachments.length) {
            uploadFilesToJob(createdJson.data.id)
          } else {
            JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobUpgrade)
          }
        })
        .fail(() => {
          document.location.href = '/500'
        })

      function buildJobSchedule() {
        if (job.schedule !== 'on_a_particular_date') { return "" }

        var [day, month, year] = job.scheduleDate.split("/")

        return new Date([year, month, day])
      }

      function buildDescription() {
        const job = data;

        let description = `${job.description}`

        if (job.extraDetails) {
          buildExtraDetails()
        }

        switch(job.schedule) {
        case 'this_week':
          description += '\n\nSchedule: Need job done this week\n\n';
          break;
        case 'next_couple_of_weeks':
          description += '\n\nSchedule: Need job done in the next couple of weeks\n\n';
          break;
        case 'on_a_particular_date':
          description += `\n\nSchedule: Need job done on ${job.scheduleDate}\n\n`
          break;
        case 'asap_urgent':
          description += '\n\nSchedule: Need job done urgently\n\n'
          break;
        default:
          description += '\n\nSchedule: Not Specified\n\n';
        }

        if (job.budget === 'yes') {
          description += `\nBudget: ${stringUtils.capitalise(job.budgetType)}`
        }
        else if (job.budgetType !== 'price_range' && job.budgetType !== 'set_price') {
          description += `\nBudget: Not Specified`
        }

        if (job.budgetType === 'price_range' && job.budgetPriceRange !== null) {
          if (job.minBudget == '5001' && job.maxBudget == '5001') {
            description += `\nBudget: More than $5000`
          } else {
            description += `\nBudget: $${job.minBudget} - $${job.maxBudget}`
          }
        }

        if (job.budgetType === 'set_price' && job.budgetSetPriceRange !== null) {
          let price = job.budgetSetPriceRange
          description += `\nBudget: $${price}`
        }

        if (job.siteVisit !== null)
        {
          let siteVisit = stringUtils.capitalise(job.siteVisit.replace(/_/g, ' '))
          description += `\n\nSite Visit: ${siteVisit}`

          if (job.siteVisitTime !== null) {
            description += `\nSite Visit: ${stringUtils.capitalise(job.siteVisitTime)}`
          }
        }

        function buildExtraDetails() {
          description += '\n\n\nSpecific Job Details: \n'
          formatExtraDetails()

          function formatExtraDetails() {
            Object.keys(job.extraDetails).forEach(function(descriptionKey) {
              if(Array.isArray(job.extraDetails[descriptionKey])) {
                description += `\n ${descriptionKey}: \n${addLeadingHyphens(descriptionKey)} \n`
              } else {
                description += `\n ${descriptionKey}: ${job.extraDetails[descriptionKey]} \n`
              }
            })
          }

          function addLeadingHyphens(key) {
            return job.extraDetails[key].map((el) => '  - ' + el).join('\n')
          }
        }

        return description
      }

      function uploadFilesToJob(jobId) {
        let files = job.attachments
        let requests = []

        var fl = files.length;

        for (var i = 0; i < fl; i++) {
          buildRequests(i)
        }

        function buildRequests(i) {
          let formData = new FormData()
          let apiData = JSON.stringify(API.V2.build.attachment({jobId: jobId}))

          formData.append('attachment', apiData)
          formData.delete && formData.delete('file') // doesn't exist on safari mobile
          formData.append('file', files[i])

          let request = API.V2.attachment({data: formData})
          request.beforeSend = () => {
            $('.status-message').text(`Uploading ${files[i].name}...`)
          }
          requests.push(request)
        }

        var slideTo = '';
        if(data.closureSurvey.job && typeof data.closureSurvey.job.id !== 'undefined') {
          slideTo = HasJobBeenDoneYet;
        } 

        function processUploads(index) {
          if (index < requests.length) {
            $.ajax(requests[index])
            .always(() => {
              processUploads(index + 1)
            })
            .fail(() => {
              setAttachmentError()
            })
          } else {
            $('.status-message').text("All Done!")
            JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobUpgrade)
          }
        }

        processUploads(0)
      }

      function setAttachmentError(){
        Cookies.set('job-attachment-error', data.id)
      }
    },

    render: function() {
      return (
        <div className="modal-body job-form-body">
          <h3>
            Saving your job details
          </h3>
          <p>
            <i className="status-message"></i>
            <div className="spinner-icon text-center" style={{marginLeft: '15px'}}/>
          </p>
        </div>
      )
    }
  })
}

export default SubmitJob
