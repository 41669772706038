import React from 'react'
import HostedFields from 'common/components/credit_card/HostedFields'
import Spinner from 'common/components/Spinner'

export default class CreditCardForm extends HostedFields {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div className="mb12">
        <div className="bg-gray-2 p16 radius-16-top">
          <span className="fs18 bold">Card Details</span>
          <span className="pull-right">           
            <img src="/assets/cc/mastercard.svg" className="w-8 h-8" style={{width: '35px', height: '35px'}}/>
            <img src="/assets/cc/visa.svg" style={{width: '35px', height: '35px'}}/>
          </span>
        </div>
        {
          this.state.useExistingCreditCard ?
          <div>
            <form id='credit-card-form' onSubmit={this.props.handleSubmitButtonOnclick}>
              {this.props.hiddenFields}
              <div className='bg-gray-2 p16 pb4 mb16 radius-16-bottom'>
                <h5 className='text-gray'>Use this card</h5>
                <div className='fs28 mb16 text-copy-1'>{this.props.ccNumber}</div>
                <div className='mb20'><a href='#' className={`btn btn-xs btn-clear ${this.props.submitting ? 'disabled' : ''}`}
                  onClick={this.toggleCreditCardInput}>{this.state.isUpdatingCreditCard ? 'Cancel Update' : 'Update Card'}</a>
                </div>
              </div>
              <div>
                {
                  this.props.errorMessage &&
                  <div className='text-red pb8'>
                    {this.props.errorMessage}
                  </div>
                }
                <div className='text-copy-1 mb12'>
                  <i>By pressing Next you agree to our Terms & Conditions and acknowledge that your credit card will be debited for the Upgrade mentioned above</i>
                </div>
                <button className='js-update-credit-card-button js-purchase-submit button btn btn-block btn-secondary radius-6 mb12 fs18' disabled={this.props.submitting}>
                  {
                    this.props.submitting ?
                    <div className='flex flex-row items-center justify-center space-x-2'>
                      <Spinner color='text-white'/>
                      {this.props.spinnerText}
                    </div>
                    :
                    <div>                  
                      <span>Next</span>
                    </div>
                  }
                </button>
                 <button className='btn btn-secondary btn-clear radius-6 btn-block mt12 fs18' onClick={this.props.previous}>
                  Previous
                </button>
              </div>
            </form>
          </div>
          :
          <form id='credit-card-form' action={this.props.action} acceptCharset='UTF-8' method='post' noValidate='novalidate' className='bv-form'>
            {this.props.hiddenFields}
          </form>
        }
        <div className={`cc-hosted-fields radius-16 ${this.state.useExistingCreditCard ? 'hidden' : ''}`}>
          <div className='wrapper'>
            <form className='form p0'>
              <div className='bg-gray-2 p16 radius-16-bottom mb12'>
                {
                  this.state.isUpdatingCreditCard &&
                  <div className='mb16'><a href='#' className={`btn btn-xs btn-clear ${this.props.submitting ? 'disabled' : ''}`}
                    onClick={this.toggleCreditCardInput}>{this.state.isUpdatingCreditCard ? 'Cancel Update' : 'Update Card'}</a>
                  </div>
                }
                <div className='cardName-container field-container'>
                  <div id='card-name-field' className='hosted-field bg-white'></div>
                  <div className='error m0'></div>
                </div>
                <div className='cardNumber-container field-container'>
                  <div id='card-number-field' className='hosted-field bg-white'></div>
                  <div className='error m0'></div>
                </div>
                <div className='field-columns'>
                  <div className='cardExpiry-container field-container'>
                    <div id='card-expiry-field' className='hosted-field bg-white'></div>
                    <div className='error m0'></div>
                  </div>
                  <div className='cardCvv-container field-container'>
                    <div id='card-cvv-field' className='hosted-field bg-white'></div>
                    <div className='error m0'></div>
                  </div>
                </div>
              </div>
              <div className='pb16'>
                <div className='response'></div>
                {
                  this.props.errorMessage &&
                  <div className='text-red pb8'>
                    {this.props.errorMessage}
                  </div>
                }
                <div className='text-copy-1 mb12'>
                  <i>By pressing Next you agree to our Terms & Conditions and acknowledge that your credit card will be debited for the Upgrade mentioned above</i>
                </div>
                <button className='button btn btn-secondary radius-6 btn-block fs18' disabled={this.props.submitting}>
                  {
                    this.props.submitting ?
                    <div className='inline'>
                      <Spinner color='text-white'/>
                      {this.props.spinnerText}
                    </div>
                    :
                    <div>            
                      <span>Next</span>
                    </div>
                  }
                </button>
                <button className='btn btn-secondary btn-clear radius-6 btn-block mt12 mb12 fs18' onClick={this.props.previous}>
                  Previous
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
