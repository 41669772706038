import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import PurchaseJobUpgrade from 'job_wizard_mobile/components/steps/PurchaseJobUpgrade'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'
import coreUtils from 'common/src/utils/coreUtils'

const JobUpgrade = {
  validator: '',
  component: React.createClass({
    componentDidMount: function() {
      $.ajax({
        url: '/jobs/get_job_upgrade_data',
        method: 'GET',
        dataType: 'json',
      }).done((data) => {
        actions.updateHostedFieldsToken(data.hosted_fields_token)
        actions.updateHostedFieldsUserId(data.hosted_fields_user_id)
        actions.updateEnvironment(data.environment)
        actions.updatePremiumListingAmount(data.premium_listing_amount)
        actions.updatePopularJobs(data.popular_jobs)
      })
    },

    redirectToJobPage: function() {
      let jobID = data.id
      let message = JSON.stringify({ message_type: 'jobs_wizard', message: 'JOB_POSTING_COMPLETED', payload: jobID })
      if (MobileChannelHandler.sendChannelMessage(message)) {
        return;
      }
      document.location.href = redirectUrl()

      function redirectUrl() {
        let jobID = data.id

        switch (data.referrer.cta) {
          case 'profile': {
            return `/jobs/${jobID}/profilejob?successful_job=true`
          }
          case 'business_directory_card': {
            return `/jobs/${jobID}/bcardjob?successful_job=true`
          }
          default: {
            return `/jobs/${jobID}/posted?successful_job=true`
          }
        }
      }
    },

    upgradeJobPost: function(e) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', PurchaseJobUpgrade)
    },
    render: function() {
      return (
        <div>
          <h3 className='mt10 mb12'>Upgrade to Attract More Quotes (Optional)</h3>
          <div className='border radius-16 mb32'>
            <div>
              <img className="width-100" src="/assets/job_page/wave.svg"/>
            </div>
            <div className='p20 pt0'>
              <span className="bold fs20">Premium</span>
              <span className="bold fs20 pull-right">${data.premiumListingAmount}</span>
              <ul className="pb12 pt12 fs18">
                <li>Your Job appears at the top of the leads list for matched businesses</li>
                <li>We send your job by SMS to all active matched businesses</li>
              </ul>
            </div>
          </div>
          <div className='nav-buttons'>
            <button className='btn btn-main radius-6 btn-block fs18 mb12' onClick={this.upgradeJobPost}>
              Upgrade for $9.95 (Recommended)
            </button>
            <button className='btn btn-secondary radius-6 btn-block fs18' onClick={this.redirectToJobPage}>
              No Thanks
            </button>
          </div>
        </div>
      )
    }
  })
}

export default JobUpgrade
