import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import coreUtils from 'common/src/utils/coreUtils'
import ZipOffer from 'job_wizard_mobile/components/steps/ZipOffer'
import PostJobAgain from 'job_wizard_mobile/components/steps/PostJobAgain'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'

const SubmitFeedback = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    onStepProgression()
  },
  
  component: React.createClass({
    componentDidMount: function() {
      this.createJobClosure()
    },
  
    createJobClosure: function() {
      $.ajax({
        url: `/jobs/job/${data.closureSurvey.job.id}/closure`,
        type: 'PUT',
        dataType: 'json',
        data: {
          survey: {
            closure: {
              outcome: data.closureSurvey.outcome,
              provider_account_id: data.closureSurvey.providerAccountId,
              value: data.closureSurvey.value
            },
            closure_history: {
              trigger: 'job_modal',
              medium:  'web'
            },
            feedback: {
              rating: data.closureSurvey.rating,
              comments: data.closureSurvey.comments
            },
            feedback_reasons: data.closureSurvey.selectedReasons,
            from_fast_feedback: false
          }
        }
      }).done((result) => {
        ////////// Temporary. Please check this ticket: https://serviceseeking.atlassian.net/browse/SALES-1703
        ///JobWizardEventEmitter.emit('job:wizard:advanceToStep', ZipOffer)

        /// remove the code below if you uncomment the code above
        if (data.closureSurvey.rating) {
          JobWizardEventEmitter.emit('job:wizard:advanceToStep', PostJobAgain)
        } else {
          this.redirectToJobPage()
        }
      }).fail((jqXHR, textStatus) => {
        document.location.href = '/500'
      })
    },

    redirectToJobPage: function () {
      let jobID = data.id
      let message = JSON.stringify({ message_type: 'jobs_wizard', message: 'JOB_POSTING_COMPLETED', payload: jobID })
      if (MobileChannelHandler.sendChannelMessage(message)) {
        return;
      }
      
      let url = ''

      switch (data.referrer.cta) {
        case 'profile': {
          url = `/jobs/${jobID}/profilejob?successful_job=true`
        }
        case 'business_directory_card': {
          url = `/jobs/${jobID}/bcardjob?successful_job=true`
        }
        default: {
          url = `/jobs/${jobID}/posted?successful_job=true`
        }
      }

      document.location.href = url 
    },

    render: function() {
      return (
        <div className="modal-body job-closure-form-body">
          <h3>Saving feedback details...
            <div className="spinner-icon text-center ml15" />
          </h3>
        </div>
      )
    }
  })
}

export default SubmitFeedback
