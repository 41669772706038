const MobileChannelHandler = {
  hasMobileChannel: function () {
    if (typeof(JobWizardChannel) == "undefined") {
      return false;
    }
    
    return true;
  },

  sendChannelMessage: function (message) {
    if (this.hasMobileChannel()) {
      JobWizardChannel.postMessage(message)
      return true;
    }

    return false;
  }
}

export default MobileChannelHandler;
