import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import ErrorMessage from 'common/components/ErrorMessage'
import NavigationButtons from 'common/components/NavigationButtons'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import GetFirstName from 'job_wizard_mobile/components/steps/GetFirstName'
import API from 'common/src/api'
import coreUtils from 'common/src/utils/coreUtils'

const GetContact = {
  validator: '',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    let validatePhone = () => {
      $.ajax({
        url: '/users/validate_contact_phone',
        dataType: 'json',
        data: { user_attributes: {
                  contact_phone: GetContact.validator.getFieldElements('contact_phone').val()
                }
              }
      }).fail(function (error) {
        document.location = "/500"
      }).done((result) => {
        if (result.valid === true) {
          createUser()
        }
        else {
          GetContact.validator.updateMessage('contact_phone', 'blank', 'Invalid Number.')
          GetContact.validator.updateStatus('contact_phone', 'INVALID', 'blank')
        }
      })
    }

    validatePhone()

    let createUser = () => {
      let subscribeToMessages = true

      var userObj = API.V2.userCreate(
        {
          email: data.emailAddress,
          name: data.firstName,
          phone: data.phoneNumber,
          'autogenerate-password': true
        },
        {
          type: 'email-preferences',
          attrs: {
            'ss-update': subscribeToMessages,
            'sms-offers': subscribeToMessages
          }
        });

      $.ajax(userObj)
        .done((data) => {
          coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'jobpost', eventAction: 'jobconvert' })
          $('#user-session-id').attr('title', data.data.id)
          window.App.currentUserId = data.data.id;
          window.App.userPhoneIsValid = data.meta['valid-phone']
          onStepProgression()
        })
        .fail((data) => {
          if (data.status === 422) {
            let res = data.responseJSON
            for (let i = 0; i < res.errors.length; i++) {
              this.errorMessage = this.errorMessage += ' ' + res.errors[i].detail
            }
          } else {
            this.errorMessage = 'Sorry we are unable to process your request at this time. Please try again later.'
            document.location.href = '/500'
          }
        })
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('#job-modal').data('bootstrapValidator').removeField('contact_phone')
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', GetFirstName)
  },

  component: React.createClass({
    componentDidMount: function() {
      $('#job-modal').bootstrapValidator({ /* */ })
      GetContact.validator = $('#job-modal').data('bootstrapValidator')
      this.setupValidation()
    },

    setupValidation: function () {
      GetContact.validator.addField('contact_phone',
        { validators: {
            notEmpty: {
              message: 'Please enter your contact number'
            },
            blank: {}
          }
        }
      )
    },

    handlePhoneNumberChange: function(e) {
      actions.updatePhoneNumber(e.target.value)
    },

    render() {
      return (
        <div>
          <ErrorMessage />
          <h3 className="mb15 mt20">What phone number can you be reached at?</h3>
          <div className="form-group">
            <div className="input-group p5 border radius-6 details-mobile">
              <input type="text" 
                     className="form-control"
                     onChange={this.handlePhoneNumberChange}
                     name="contact_phone" 
                     id="contact-phone"
                     defaultValue={data.phoneNumber}
              />
              <span className="input-group-btn">
                <NavigationButtons
                  navButtonLayout='okButton'
                  onStepProgression={this.props.onStepProgression}
                /> 
              </span>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default GetContact
