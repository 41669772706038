import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import SubmitFeedback from 'job_wizard_mobile/components/steps/SubmitFeedback'
import WhoDidTheJob from 'job_wizard_mobile/components/steps/WhoDidTheJob'
import HowMuchDidYouPay from 'job_wizard_mobile/components/steps/HowMuchDidYouPay'

const RateExperience = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.closureSurvey.rating !== null && data.closureSurvey.rating < 3) {
      if (data.closureSurvey.selectedReasons.length < 1) {
        $(".reasons-error-message").removeClass('hidden')
      }
      else (
        onStepProgression()
      )
    }
    else {
      onStepProgression()
    }
  },

  component: React.createClass({
    getInitialState: function() {
      const closureSurvey = data.closureSurvey;

      return {
        rating: closureSurvey.rating,
        starClassDefault: "font-24 text-copy-0-regular p8 ",
        showReasons: false,
        showLeaveAComment: false
      }
    },

    handleRatingSubmit: function() {
      if (data.closureSurvey.value !== null && typeof data.closureSurvey.value !== 'undefined') {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', SubmitFeedback)
      }
      else {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', HowMuchDidYouPay)
      }
    },

    handleRatingBack: function() {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', WhoDidTheJob)
    },

    handleLowRatingClick: function(e, value) {
      data.closureSurvey.rating = value
      this.setState({ showReasons: true, showLeaveAComment: true, rating: value })
    },

    handleHighRatingClick: function(e, value) {
      data.closureSurvey.rating = value
      data.closureSurvey.selectedReasons = []
      this.setState({ showReasons: false, showLeaveAComment: true, rating: value })
    },

    changeStarToFull: function($star) {
      $star.removeClass("ficon-star-empty")
           .addClass("ficon-star-full")
    },

    changeStarToEmpty: function($star) {
      $star.removeClass("ficon-star-full")
           .addClass("ficon-star-empty")
    },

    handleMouseHoverOnStarOne: function(e) {
      this.changeStarToFull($('#survey_feedback_rating_1'))
      this.changeStarToEmpty($('#survey_feedback_rating_2'))
      this.changeStarToEmpty($('#survey_feedback_rating_3'))
      this.changeStarToEmpty($('#survey_feedback_rating_4'))
      this.changeStarToEmpty($('#survey_feedback_rating_5'))
    },

    handleMouseOutOnStarOne: function(e) {
      this.changeStarToEmpty($('#survey_feedback_rating_1'))
    },

    handleMouseHoverOnStarTwo: function(e) {
      this.handleMouseHoverOnStarOne()
      this.changeStarToFull($('#survey_feedback_rating_2'))
    },

    handleMouseOutOnStarTwo: function(e) {
      this.handleMouseOutOnStarOne()
      this.changeStarToEmpty($('#survey_feedback_rating_2'))
    },

    handleMouseHoverOnStarThree: function(e) {
      this.handleMouseHoverOnStarTwo()
      this.changeStarToFull($('#survey_feedback_rating_3'))
    },

    handleMouseOutOnStarThree: function(e) {
      this.handleMouseOutOnStarTwo()
      this.changeStarToEmpty($('#survey_feedback_rating_3'))
    },

    handleMouseHoverOnStarFour: function(e) {
      this.handleMouseHoverOnStarThree()
      this.changeStarToFull($('#survey_feedback_rating_4'))
    },

    handleMouseOutOnStarFour: function(e) {
      this.handleMouseOutOnStarThree()
      this.changeStarToEmpty($('#survey_feedback_rating_4'))
    },

    handleMouseHoverOnStarFive: function(e) {
      this.handleMouseHoverOnStarFour()
      this.changeStarToFull($('#survey_feedback_rating_5'))
    },

    handleMouseOutOnStarFive: function(e) {
      this.handleMouseOutOnStarFour()
      this.changeStarToEmpty($('#survey_feedback_rating_5'))
    },

    handleMouseOutOnAllStars: function(e) {
      if (this.state.rating) {
        this.handleMouseHoverOnStarOne()
      }
      if (parseInt(this.state.rating) >= 2) {
        this.handleMouseHoverOnStarTwo()
      }
      if (parseInt(this.state.rating) >= 3) {
        this.handleMouseHoverOnStarThree()
      }
      if (parseInt(this.state.rating) >= 4) {
        this.handleMouseHoverOnStarFour()
      }
      if (parseInt(this.state.rating) === 5) {
        this.handleMouseHoverOnStarFive()
      }
    },

    handleCommentsOnChange: function(e) {
      data.closureSurvey.comments = e.target.value
    },

    handleFeedbackReasonsOnChange: function(e) {
      const reasons = data.closureSurvey.selectedReasons

      if (e.target.checked) {
        $(".reasons-error-message").addHidden()

        reasons.push({feedback_reason_id: e.target.value, selected: "true"})
      } else {
        let index = reasons.indexOf({feedback_reason_id: e.target.value, selected: "true"})
        reasons.splice(index, 1)
      }
    },
    render: function() {
      let label = data.closureSurvey.rating ? "Next" : "No thanks, I don't want to leave a rating yet"
      return (
        <div>
          <h3 className="mb15 mt20">Rate your experience with {data.closureSurvey.providerAccountName}</h3>
          <div className="form-group">
            <div className="col-xs-12 white-box bordered text-center p20 pt32 pb32 mb30" id="star-rating-input" onMouseOut={this.handleMouseOutOnAllStars}>
              <span className={this.state.starClassDefault + (this.state.rating ? "ficon-star-full" : "ficon-star-empty")}
                    id="survey_feedback_rating_1"
                    onMouseOver={this.handleMouseHoverOnStarOne}
                    onMouseOut={this.handleMouseOutOnStarOne}
                    onClick={(e) => this.handleLowRatingClick(e, "1")}>
              </span>
              <span className={this.state.starClassDefault + ((parseInt(this.state.rating) >= 2) ? "ficon-star-full" : "ficon-star-empty")}
                    id="survey_feedback_rating_2"
                    onMouseOver={this.handleMouseHoverOnStarTwo}
                    onMouseOut={this.handleMouseOutOnStarTwo}
                    onClick={(e) => this.handleLowRatingClick(e, "2")}>
              </span>
              <span className={this.state.starClassDefault + ((parseInt(this.state.rating) >= 3) ? "ficon-star-full" : "ficon-star-empty")}
                    id="survey_feedback_rating_3"
                    onMouseOver={this.handleMouseHoverOnStarThree}
                    onMouseOut={this.handleMouseOutOnStarThree}
                    onClick={(e) => this.handleHighRatingClick(e, "3")}>
              </span>
              <span className={this.state.starClassDefault + ((parseInt(this.state.rating) >= 4) ? "ficon-star-full" : "ficon-star-empty")}
                    id="survey_feedback_rating_4"
                    onMouseOver={this.handleMouseHoverOnStarFour}
                    onMouseOut={this.handleMouseOutOnStarFour}
                    onClick={(e) => this.handleHighRatingClick(e, "4")}>
              </span>
              <span className={this.state.starClassDefault + ((parseInt(this.state.rating) === 5) ? "ficon-star-full" : "ficon-star-empty")}
                    id="survey_feedback_rating_5"
                    onMouseOver={this.handleMouseHoverOnStarFive}
                    onMouseOut={this.handleMouseOutOnStarFive}
                    onClick={(e) => this.handleHighRatingClick(e, "5")}>
              </span>
            </div>
            { (this.state.showLeaveAComment === true || this.state.rating) &&
              <div>
                <label className="mb5 mt20">Leave a comment (Optional)</label>
                <textarea
                  className="component-block mt15 p16 h120 form-control"
                  id="survey_feedback_comments"
                  name="survey_feedback_comments"
                  onChange={this.handleCommentsOnChange}
                />
              </div>
            }
            <div className= "pt30">
              <div className="reasons-error-message alert alert-danger hidden">
                Please select at least one to be published on Business Profile
              </div>

              <div className="row">
                <div className="col-xs-12 pb10">
                  <a href="#" id="modal-nav-one-button" className="btn btn-block btn-main" style={{'whiteSpace': 'normal'}} onClick={this.handleRatingSubmit}>
                    {label}
                  </a>
                </div>
                <div className="col-xs-12">
                  <a href="#" id="modal-nav-one-button" className="btn btn-block btn-clear" style={{'whiteSpace': 'normal'}} onClick={this.handleRatingBack}>
                    Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default RateExperience;
