import React from 'react'
import API from 'common/src/api'

const VerifyNumber = {
  displayName: 'Number Verification',

  buttonLayout() {
    return 'none'
  },

  onSubmit: function(onStepProgression) {
    onStepProgression()
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        loading: false,
        submitEnabled: false,
        verificationStatus: '',
        maxCodeLength: 4
      }
    },

    componentDidMount: function() {
      this.setupPhoneVerification()
    },

    updateState: function(e) {
      var codeLength = this.getCode().length

      if (codeLength === this.state.maxCodeLength) {
        this.autoSubmitVerificationCode()
      } else if (codeLength > this.state.maxCodeLength) {
        $(e.target).val(this.getCode().slice(0, this.state.maxCodeLength))
      }
    },

    setupPhoneVerification: function(e) {
      var setupPhoneVerification = API.V2.setupPhoneVerification()

      setupPhoneVerification.beforeSend = () => {
        this.setState({ loading: true })
      }

      $.ajax(setupPhoneVerification)
        .done((data) => {
          this.setState({ verificationStatus: data.verification_status })
        })
        .always(() => {
          this.setState({ loading: false }, () => {
            $('#verifyNumberForm').find('input').first().focus()
          })
        })
    },

    skipPhoneVerification: function() {
      var skipPhoneVerification = API.V2.skipPhoneVerification()

      skipPhoneVerification.beforeSend = () => {
        this.setState({ loading: true })
      }

      $.ajax(skipPhoneVerification)
        .always(() => {
          this.setState({ loading: false,
                          verificationStatus: 'skipped' })
        })
    },

    getCode: function() {
      return $('#verifyNumberForm input').val()
    },

    onSubmit: function(e) {
      this.verifyPhone().beforeSend = () => {
        this.setState({ loading: true })
      }

      $.ajax(this.verifyPhone())
        .done((data) => {
          this.setState({ verificationStatus: data.verification_status })
        })
        .fail(() => {
          this.setState({ verificationStatus: 'failed',
                          submitEnabled: false })
        })
        .always(() => {
          this.setState({ loading: false })
        })
    },

    autoSubmitVerificationCode: function() {
      this.verifyPhone().beforeSend = () => {
        this.setState({ loading: true })
      }

      $.ajax(this.verifyPhone())
        .done((data) => {
          this.setState({ verificationStatus: data.verification_status })
        })
        .always(() => {
          this.setState({ loading: false,
                          submitEnabled: true })
        })
    },

    verifyPhone: function() {
      return API.V2.verifyPhone({ code: this.getCode() })
    },

    render: function() {
      var status = this.state.verificationStatus

      if (status == 'verified' || status == 'unavailable' || status == 'skipped') {
        this.props.onStepProgression()
      }

      return (
        <div>
          { this.state.loading ?
            <div className="modal-body">
              <div className="spinner-icon text-center"/>
            </div>
            :
            <div className="row pl24 pr24 pt16 pb0">
              <div className="col-sm-12 col-xs-12 mt0 mb0 pl0 pr0">
                <div className="text-center font-21 bold">
                  Verify your phone number
                </div>
                <div className="mt24 mb0">
                  <div className="mb32 text-center text-copy-0">
                    Enter the 4 digit code sent to your mobile number
                  </div>
                  <div className="number-verification" id="verifyNumberForm">
                    <div className="row mt24 mb8 font-24 bold col-md-10 col-centered pl0 pr0 sm-pl16 sm-pr16">
                      <div className="col-sm-3 col-xs-12">
                        <input className="text-center"
                               type="number"
                               maxLength={this.state.maxCodeLength}
                               inputMode="numeric"
                               pattern="[0-9]*"
                               onChange={this.updateState} />
                      </div>
                    </div>
                    <div className="col-sm-12 col-xs-12 p0 mt24 mb0">
                      <div className='col-sm-12 col-xs-12'>
                        <a href="#" id="submitVerification"
                           className={`btn btn-block btn-secondary p12 radius-6 ${(this.state.submitEnabled ? '' : 'disabled')}`}
                           onClick={this.onSubmit}>
                          Continue
                          <i className='ficon-arrow-right-shaft pl10 fs14' />
                        </a>
                      </div>
                    </div>

                    <div className={`col-sm-12 col-xs-12 mt8 mb0 ${(this.state.verificationStatus === 'failed') ? '' : 'hidden'}`}>
                      <span className="col-sm-12 col-xs-12 mb32 text-center text-red">
                        There was a problem verifying your number
                      </span>
                    </div>

                    <div className="col-sm-12 col-xs-12 mt24 mb0">
                      <span className="col-sm-12 col-xs-12 mb32 text-center text-copy-0">
                        Didn't get the SMS? <a className="js-resend-code" href="#" onClick={this.setupPhoneVerification}>Send it again</a>
                      </span>
                    </div>

                    <div className="col-sm-12 col-xs-12 mb0">
                      <span className="col-sm-12 col-xs-12 mb20 text-center text-copy-0">
                        <a href="#" onClick={this.skipPhoneVerification}>Skip this step</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      )
    }
  })
}

export default VerifyNumber
