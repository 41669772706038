import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import CreditCardForm from 'job_wizard_mobile/components/CreditCardForm'
import JobUpgrade from 'job_wizard_mobile/components/steps/JobUpgrade'
import JobUpgradePosted from 'job_wizard_mobile/components/steps/JobUpgradePosted'
import coreUtils from 'common/src/utils/coreUtils'

const PurchaseJobUpgrade = {
  validator: '',
  component: React.createClass({
    getInitialState: function() {
      return {
        errorMessage: null,
        submitting: false,
        ccNumber: data.cc_number,
      }
    },

    previous: function(e) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobUpgrade)
    },

    handleSubmitButtonOnclick: function(e) {
      e.preventDefault()

      $.ajax({
        url: `/users/v2/jobs/${data.id}/purchase_job_upgrade`,
        method: 'POST',
        dataType: 'json',
        data: { mobile: true },
        beforeSend: () => {
          this.setState( { submitting: true} )
        }
      }).done((data) => {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobUpgradePosted)
      })
      .fail((data) => {
        this.setState( { errorMessage: data.responseJSON.message, submitting: false, ccNumber: data.responseJSON.updated_cc } )

        actions.updateHostedFieldsToken(data.responseJSON.hosted_fields.table.token)
        actions.updateHostedFieldsUserId(data.responseJSON.hosted_fields.table.user_id )
      })
    },

    callback: function(creditCardId) {
      $.ajax({
        url: `/users/v2/jobs/${data.id}/purchase_job_upgrade`,
        method: 'POST',
        dataType: 'json',
        data: { credit_card_id: creditCardId, mobile: true },
        beforeSend: () => {
          this.setState( { submitting: true} )
        }
      }).done((data) => {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobUpgradePosted)
      })
      .fail((data) => {
        this.setState( { errorMessage: data.responseJSON.message, submitting: false, ccNumber: data.responseJSON.updated_cc, hostedFieldsToken: data.responseJSON.hosted_fields.table.token, hostedFieldsUserId: data.responseJSON.hosted_fields.table.user_id } )
      })
    },

    render: function() {
      return (
        <div className='step'>
          <h3 className='mt10 mb12'>Upgrade to Attract More Quotes (Optional)</h3>
          <div className='credit-card'>
            <div className='bg-gray-2 radius-16 mb20 p16'>
              <div className="bold fs20 text-blue mb8">Premium</div>
              <span className="bold fs20">Total</span>
              <span className="bold fs20 pull-right">${data.premiumListingAmount}</span>
            </div>
            <CreditCardForm
              action={`/users/v2/jobs/${data.id}/purchase_job_upgrade`}
              buttonText='Next'
              handleSubmitButtonOnclick={this.handleSubmitButtonOnclick}
              callback={this.callback}
              ccNumber={this.state.ccNumber}
              hostedFieldsToken={data.hostedFieldsToken}
              hostedFieldsUserId={data.hostedFieldsUserId}
              spinnerText='Purchasing...'
              withExistingCard={data.with_cc}
              errorMessage={this.state.errorMessage}
              environment={this.state.environment}
              submitting={this.state.submitting}
              previous={this.previous}
            />
          </div>
        </div>
      )
    }
  })
}

export default PurchaseJobUpgrade
