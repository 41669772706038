import React from 'react'

const UploadFile =  function(props) {
  let acceptedTypes = "image/png, image/jpeg, text/plain, application/pdf"

  if (props.accept) {
    acceptedTypes = props.accept
  }

  return (
    <div>
      <label className={`ficon-arrow-up-shaft radius-6 m0 ` + props.buttonClass} type="button" htmlFor={props.id}>{props.labelTitle}</label>
      <input type="file" className="hidden" id={props.id} multiple onChange={props.handleFiles} />
    </div>
  )
}

export default UploadFile