import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import Category from 'common/components/job_wizard/steps/Category'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'

const SelectCategory = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion) {
    let details = this

    details.selectExactMatchForSubcategory(() => {
      if (data.backToSubmit === true){
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
      }
      else {
        onStepProgression()
      }
    })
  },

  buttonLayout: function() {
    return 'downButton'
  },

  searchTerm() {
    return $('input[name="category"]').val()
  },

  selectExactMatchForSubcategory(callback) {
    $.ajax({
      url: '/api/sub_categories/lookup.json',
      dataType: 'json',
      data: { 'q': this.searchTerm(), 'prefer_keywords': '1' }
    }).done((response) => {
      if ('keyword' in response) {
        let category = { id: response.subcategory_id, value: response.subcategory_name }
        actions.updateCategory(category)
        actions.updateSubCategory(category, 'add')
        actions.updateKeyword(response.keyword)
      } else {
        for (let item of response) {
          if (this.searchTerm().toLowerCase() == item.name.toLowerCase()) {
            let category = { id: item.id, value: item.name }
            actions.updateCategory(category)
            actions.updateSubCategory(category, 'add')
          }
        }
      }
      callback()
    })
  },

  component: React.createClass({
    getInitialState: function() {
      return { showCategoryInput: true }
    },

    componentDidMount: function() {
      actions.resetCategories()
      JobWizardEventEmitter.addListener('job:wizard:hideCategoryInput', this.updateState)
    },

    componentWillUnmount: function() {
      JobWizardEventEmitter.removeListener('job:wizard:hideCategoryInput', this.updateState)
    },

    updateState: function(data) {
      this.setState(data)
    },

    render: function() {
      return (
        <div className="form-horizontal">
          <div className={`${this.state.showCategoryInput ? '' : 'hidden'}`}>
            <div className="form-group mb24">
              <div className="col-xs-12 details-mobile">
                <h3 className="mt10">Who or what do you need?</h3>
                <div className="font-14 text-copy-0 pb5">e.g Tiler, Cleaner, Electrician</div>
                <div className="input-group p5 border radius-6">
                  <Category />
                  <span className="input-group-btn">
                    <NavigationButtons
                      navButtonLayout='okButton'
                      onStepProgression={this.props.onStepProgression}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default SelectCategory
