import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import StepSequence from 'job_wizard_mobile/components/StepSequence'
import ChooseSiteVisit from 'job_wizard_mobile/components/steps/ChooseSiteVisit'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'
import coreUtils from 'common/src/utils/coreUtils'

const SiteVisitTime = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true) {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('.choose-site-visit-time').addClass('hidden')
    $('#job-modal').data('bootstrapValidator').removeField('site_visit_time')
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', ChooseSiteVisit)
  },

  component: React.createClass({
    getInitialState: function() {
      const job = data;

      return {
        siteVisitTime: job.siteVisitTime
      }
    },

    componentDidMount: function() {
      if (data.siteVisit === "no_site_visit_required" || data.siteVisit === "anytime") {
        this.props.onStepProgression()
      }

      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')
      $('#site-visit-time').radioButtonGroup({activeRowClass: 'active'})

      this.componentDoesValidate()
    },

    autoProgress: function() {
      if (data.backToSubmit === true){
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
      }
      else {
        this.props.onStepProgression()
      }
    },

    siteVisitTimeChanged: function(e) {
      actions.updateSiteVisitTime(e.currentTarget.querySelector('[type="radio"]').value)
      this.retestValidationsToRemoveErrorsOn('site_visit_time')
      this.autoProgress()
    },

    validator: undefined,

    retestValidationsToRemoveErrorsOn: function(fieldName) {
      this.validator.$form.data('bootstrapValidator').revalidateField(fieldName)
    },

    componentDoesValidate: function() {
      const validator = this.validator;
      validator.addField('site_visit_time',
        {
          validators: {
            notEmpty: {
              message: 'Please choose a "Preferred Time"'
            }
          }
        }
      )
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden  = `${radioButtonVisible} hidden`

      return (
        <div>
          <div className="form-group choose-site-visit-time" onClick={this.autoProgress}>
            <h3 className="mb15 mt15">Preferred Time?</h3>
            <div className="radio-button-box minor-bold" id="site-visit-time">
              <div className="radio" onClick={this.siteVisitTimeChanged}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10" id='business-hours'>Business Hours</div>
                  <input type="radio" name="site_visit_time" value="business-hours" className="form-control invisible" />
                </div>
              </div>
              <div className="radio" role="tab" onClick={this.siteVisitTimeChanged}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Evenings 5pm Onwards</div>
                  <input type="radio" name="site_visit_time" value="evenings" className="form-control invisible" />
                </div>
              </div>
              <div className="radio" onClick={this.siteVisitTimeChanged}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Anytime</div>
                  <input type="radio" name="site_visit_time" value="anytime" className="form-control invisible" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default SiteVisitTime
