import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import postingProgressStore from 'common/components/job_wizard/data/posting_progress/store'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import EnterDescription from 'job_wizard_mobile/components/steps/EnterDescription'
import LoginOmniauth from 'job_wizard_mobile/components/steps/LoginOmniauth'
import API from 'common/src/api'
import coreUtils from 'common/src/utils/coreUtils'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'

const EnterEmailAddress = {
  validator: '',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    let emailCheck = () => {
      $.ajax({
        url: window.App.url + '/api/users/check_email',
        dataType: 'json',
        cache: false,
        data: { email: data.emailAddress }
      }).fail(function () {
        document.location = "/500"
      }).done((result) => {
        if (result.status === 'true') {
          actions.updateMustCreateAccount(false)
          actions.updateFirstName(result.name)
          actions.updatePhoneNumber(result.phone)
          window.App.currentUserStatus = result.userStatus
          onStepProgression()
        } else if (result.status === 'valid') {
          actions.updateMustCreateAccount(true)
          onStepProgression()
        } else if (result.status === 'invalid') {
          this.validator.updateMessage('email_address', 'notEmpty', result.error)
          this.validator.updateStatus('email_address', 'INVALID', 'notEmpty')
        }
      })
    }

    this.validator.validateField('email_address') && emailCheck()
  },

  onLeftNavButtonClick(onStepProgression) {
    $('#job-modal').data('bootstrapValidator').removeField('email_address')
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', EnterDescription)
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        emailAddress: data.emailAddress
      }
    },

    componentDidMount: function() {
      $('#job-modal').bootstrapValidator({ /* */ })
      EnterEmailAddress.validator = $('#job-modal').data('bootstrapValidator')
      this.setupValidation()
    },

    setupValidation: function () {
      EnterEmailAddress.validator.addField('email_address', {
        threshold: 9999999,
        validators: {
          notEmpty: {
            message: 'Please enter an email address'
          }
        }
      })
    },

    handleEmailAddressChange: function(e) {
      let emailAddressOriginal = e.target.value
      let emailAddressTrimmed = e.target.value.replace(/[\s]/g, '')

      if (emailAddressOriginal !== emailAddressTrimmed) {
        e.target.value = emailAddressTrimmed
      }
      
      this.setState({ emailAddress: emailAddressTrimmed })
      actions.updateEmailAddress(emailAddressTrimmed)
      actions.updateOtp(null)
      actions.resetOmniAuthDetails()
      actions.updateOmniAuthDetails({'account_from_omni_auth': false})
      actions.updateOmniAuthProvider(null)
    },

    handleOmniAuthClick: function(e) {
      $('#job-modal').data('bootstrapValidator').removeField('email_address')
      actions.updateOmniAuthDetails({'account_from_omni_auth': true})
      actions.updateOmniAuthProvider(e.currentTarget.value)
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', LoginOmniauth)
    },

    render: function() {
      return (
        <div>
          <div>
            <h3 className="mb8 mt10">What email address would you like your quotes sent to?</h3>
            <small className="info-text mb8">
              <i className="block">
                name@sample.com
              </i>
            </small>
            <div className="form-group">
              <div className="input-group p5 border radius-6 details-mobile">
                <span>
                  <input type="text" className="form-control" id="email_address"
                    name="email_address"
                    defaultValue={this.state.emailAddress}
                    onChange={this.handleEmailAddressChange}
                  />
                </span>
                <span className="input-group-btn">
                  <NavigationButtons
                    navButtonLayout='okButton'
                    onStepProgression={this.props.onStepProgression}
                  />    
                </span>
              </div>
            </div>
            <div>
              <p className={`text-copy-0 pb8 text-center ${MobileChannelHandler.hasMobileChannel() ? 'hidden' : ''}`}><i className="ficon-minus"/><b> OR </b><i className="ficon-minus"/></p>
              <button className={`btn btn-facebook btn-clear btn-lg btn-block  ${MobileChannelHandler.hasMobileChannel() ? 'hidden' : ''}`} type="button" id="btn-facebook-modal" onClick={this.handleOmniAuthClick} value='Facebook'>
                <div className="i ficon-facebook text-white">
                  <span className="pl8">Login with Facebook</span>
                </div>
              </button>
              <button className={`btn btn-clear btn-block  ${MobileChannelHandler.hasMobileChannel() ? 'hidden' : ''}`} type="button" id="btn-google-modal" onClick={this.handleOmniAuthClick} value='Google'>
                <img className="img-google" src={'/assets/google-logo-icon.png'}/>
                <span className="pl8">Sign in with Google</span>
              </button>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default EnterEmailAddress
