import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import StepSequence from 'job_wizard_mobile/components/StepSequence'
import ChooseBudget from 'job_wizard_mobile/components/steps/ChooseBudget'
import BudgetPrice from 'job_wizard_mobile/components/steps/BudgetPrice'
import ChooseSchedule from 'job_wizard_mobile/components/steps/ChooseSchedule'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'
import coreUtils from 'common/src/utils/coreUtils'

const ChooseSiteVisit = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true && data.siteVisitTime !== null){
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('.choose-site-visit').addClass('hidden')
    $('#job-modal').data('bootstrapValidator').removeField('site_visit')

    JobWizardEventEmitter.emit('job:wizard:advanceToStep', ChooseBudget)
  },

  component: React.createClass({
    getInitialState: function() {
      const job = data;

      return {
        siteVisit: job.siteVisit
      }
    },

    componentDidMount: function() {
      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')

      $('#site-visit-radio-group').radioButtonGroup({activeRowClass: 'active'})
      this.componentDoesValidate()
    },

    handleSiteVisitAdvance: function(e) {
      this.siteVisitChanged(e)
      this.autoProgress()
    },

    autoProgress: function() {
      if (data.backToSubmit === true && data.siteVisitTime !== null){
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
      }
      else {
        this.props.onStepProgression()
      }
    },

    siteVisitChanged: function(e) {
      actions.updateSiteVisit(e.currentTarget.querySelector('[type="radio"]').value)
      this.retestValidationsToRemoveErrorsOn('site_visit')
    },

    retestValidationsToRemoveErrorsOn: function(fieldName) {
      this.validator.$form.data('bootstrapValidator').revalidateField(fieldName)
    },

    componentDoesValidate: function() {
      const validator = this.validator;

      $(function() { // doc ready
        validator.addField('site_visit',
          {
            validators: {
              notEmpty: {
                message: 'Please choose if you require a "Site Visit"'
              }
            }
          }
        )
      }) // end doc ready
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden  = `${radioButtonVisible} hidden`

      return (
        <div>
          <div className="form-group choose-site-visit">
            <h3 className="mb15 mt10">If your job needs a site visit, what day(s) would suit you?</h3>
            <div className="mb15">
              <small className="info-text"><i>With some jobs, businesses require a site visit or face to face meeting before providing you an accurate quote</i></small>
            </div>
            <div className="radio-button-box minor-bold" id="site-visit-radio-group">
              <div className="radio" onClick={this.handleSiteVisitAdvance}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Anytime</div>
                  <input type="radio" name="site_visit" value="anytime" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio" onClick={this.handleSiteVisitAdvance}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10" id="weekdays">Weekdays</div>
                  <input type="radio" name="site_visit" value="weekdays" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio" onClick={this.handleSiteVisitAdvance}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Weekend</div>
                  <input type="radio" name="site_visit" value="weekends" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio" onClick={this.handleSiteVisitAdvance}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Strictly NO site visit required</div>
                  <input type="radio" name="site_visit" value="no_site_visit_required" className="form-control invisible"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default ChooseSiteVisit
