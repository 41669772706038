import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import RadioButtonRow from 'common/components/RadioButtonRow'
import ChooseSchedule from 'job_wizard_mobile/components/steps/ChooseSchedule'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'

const ChooseBudget = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true && data.budgetInMind === 'no'){
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('#job-modal').data('bootstrapValidator').removeField('budget_select')
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', ChooseSchedule)
  },

  component: React.createClass({
    componentDidMount: function() {
      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')

      $('#budget-selection').radioButtonGroup({activeRowClass: 'active'})

      this.componentDoesValidate()
    },

    budgetInMindChanged: function(e) {
      let val = $(e.currentTarget).data('value')
      actions.updateBudgetInMind(val)

      this.validator.updateStatus($("[name='budget_select']"), "VALID")
    },

    handleBudgetClick: function(e) {
      this.budgetInMindChanged(e)

      $('#budget-amount').addClass('hidden')

      this.autoProgress()
    },

    autoProgress: function() {
      if (data.backToSubmit === true && data.budgetInMind === 'no'){
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
      }
      else {
        this.props.onStepProgression()
      }
    },

    componentDoesValidate: function() {
      this.validator.addField('budget_select',
        {
          validators: {
              notEmpty: {
                message: 'Please choose if you have a budget in mind'
              }
            }
        }
      )
    },

    render: function() {
      let radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden = `${radioButtonVisible} hidden`

      return (
        <div className="form-group">
          <h3 className="mb15 mt10">Do you have a budget in mind?</h3>
          <ul className="nav nav-tabs nav-justified ss-tabs" role="tablist" id="budget">
            <li role="presentation">
              <a href="#budget-yes" name="budget_select" role="tab" data-toggle="tab" data-value="yes" className="yes" onClick={this.handleBudgetClick}>Yes</a>
            </li>
            <li>
              <a href="#budget-no" name="budget_select" role="tab" data-toggle="tab" data-value="no" className="no" id="budget-no-button" onClick={this.handleBudgetClick}>No</a>
            </li>
          </ul>
        </div>
      )
    }
  })
}

export default ChooseBudget
