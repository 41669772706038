import React   from 'react'
import actions from 'common/components/job_wizard/data/actions'
import data from 'common/components/job_wizard/data/data'
import API from 'common/src/api'
import coreUtils from 'common/src/utils/coreUtils'
import 'common/components/job_wizard/utils/custom_check_boxes'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import EnterEmailAddress from 'job_wizard_mobile/components/steps/EnterEmailAddress'
import AddAttachment from 'job_wizard_mobile/components/steps/AddAttachment'
import VerifyNumber from 'job_wizard_mobile/components/steps/VerifyNumber'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'
import GetContactPreference from 'job_wizard_mobile/components/steps/GetContactPreference'

const CanCall = {
  contactPreferenceMinimumQuotes: 6,

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion) {
    let phoneNumberGroup      = $('.phone-number-group')
    let phoneNumberVisible    = phoneNumberGroup.is(':visible')
    let phoneNumber           = phoneNumberGroup.find('.js-phone-number').val()
    let userId                = window.App.currentUserId

    if (this.contactByPhone() && phoneNumberVisible) {
      var sessionObj = API.V2.userUpdate({
        'contact-phone': phoneNumber
      }, userId)

      $.ajax(sessionObj)
       .always(onRequestCompletion)
       .fail(function(r) {
          if (r.status === 422 && r.responseJSON.errors.length > 0){
            let error = r.responseJSON.errors[0].detail === 'must be filled' ?  "Please enter a phone number" : r.responseJSON.errors[0].detail
            phoneNumberGroup.find('.error-message').text(error)
          }
       })
       .done((response) => {
         window.App.userPhoneIsValid = true
         onStepProgression()
       })
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('#job-modal').data('bootstrapValidator').removeField('phone-number')

    JobWizardEventEmitter.emit('job:wizard:advanceToStep', EnterEmailAddress)
  },

  contactByPhone: function() {
    return data.canCall
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        isPhoneNumberHidden: true,
        loading: true
      }
    },

    componentDidMount: function() {
      $('#contact-radio-button-group').checkBoxGroup({activeRowClass: 'active'});
      actions.updateContactOptions(true)

      if (window.App.currentUserId !== null && typeof window.App.currentUserId !== "undefined") {
        if (coreUtils.userHasValidPhone()) {
          JobWizardEventEmitter.emit('job:wizard:advanceToStep', VerifyNumber)
        }
        else {
          this.getContactPhone()
        }
      }
      else {
        this.props.onStepProgression()
      }
    },

    handleDoNotCallClick: function(e) {
      $('#job-modal').data('bootstrapValidator').removeField('phone-number')
      actions.updateContactOptions(false)
      if (MobileChannelHandler.hasMobileChannel()) {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', GetContactPreference)
      } else {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', AddAttachment)
      }
    },

    handlePhoneNumberChange: function() {
      $('.phone-number-group .error-message').text('')
    },

    getContactPhone: function(){
      let userId = window.App.currentUserId

      var sessionObj = API.V2.userGet({
        id: userId
      })

      $.ajax(sessionObj)
        .always(() => {
          this.setState({ loading: false })
        })
        .fail(function () {
          document.location = "/500"
        })
        .done((response) => {
          actions.updatePhoneNumber(response.data.attributes.phone)
          this.setState({ isPhoneNumberHidden: response.meta['valid-phone'] })

          if (response.meta['valid-phone']) {
            window.App.userPhoneIsValid = true
            JobWizardEventEmitter.emit('job:wizard:advanceToStep', VerifyNumber)
          }
        })
    },

    render: function() {
      return (
        <div className="form-group">
          <h3 className="mb15 mt10">Are you ok with businesses contacting you via phone calls and SMS?</h3>
          <div className={`${this.state.loading ? '' : 'hidden'}`}>
            <div className="modal-body job-form-body">
              <div className="spinner-icon text-center"/>
            </div>
          </div>
          <div className={`${this.state.loading ? 'hidden' : ''}`}>
            <div className="radio-button-box minor-bold" id="contact-radio-button-group">
              <div className="radio active">
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Yes</div>
                  <div className="col-xs-2 col-sm-2 circle">
                    <i className="ficon-circle-x pull-right"></i>
                  </div>
                  <input type="radio" defaultChecked='checked' name="contact" value="true" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio" onClick={this.handleDoNotCallClick}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">No, Email and Chat ONLY</div>
                  <input type="radio" name="contact" value="false" className="form-control invisible"/>
                </div>
              </div>
            </div>

            <div className={"mt20 form-group phone-number-group " + (this.state.isPhoneNumberHidden ? 'hidden' : 'active')}>
              <label style={{marginRight: '10px'}}>Phone Number</label>
              <div className="input-group p5">
                <span>
                  <input
                    name="phone-number"
                    type="textbox"
                    className="form-control js-phone-number"
                    maxLength="30"
                    size="30"
                    onChange={this.handlePhoneNumberChange}/>
                </span>
                <span className="input-group-btn">
                  <NavigationButtons
                    navButtonLayout='okButton'
                    onStepProgression={this.props.onStepProgression}
                  />
                </span>
              </div>
              <div className="row mr20">
                <div className="mt10 pull-right">e.g. 0491 570 110 or 02 7010 1111</div>
              </div>

              <p className="error-message"></p>
            </div>
          </div>
        </div>
      )

    }
  })
}

export default CanCall
