import React from 'react'
import data from 'common/components/job_wizard/data/data'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'

const SubmitConsumerLead = {
  component: React.createClass({
   componentDidMount: function() {
     $.ajax({
        type: 'POST',
        url: '/consumer_leads',
        data: {consumer_leads: data.consumerLeads, job_id: data.id}
      }).done((data) => { 
          if (data.status === 200) {
            if (data.interested.length > 0) {
              window.open('https://zip.co/serviceseeking', '_blank')
            }
            
            this.redirectToJobPage()
          } 
        })
        .fail(() => { document.location.href = '/500' });
   },
    redirectToJobPage: function () {
      let jobID = data.id
      let message = JSON.stringify({ message_type: 'jobs_wizard', message: 'JOB_POSTING_COMPLETED', payload: jobID })
      if (MobileChannelHandler.sendChannelMessage(message)) {
        return;
      }
     
      let url = ''
      switch (data.referrer.cta) {
        case 'profile': {
          url = `/jobs/${jobID}/profilejob?successful_job=true`
        }
        case 'business_directory_card': {
          url = `/jobs/${jobID}/bcardjob?successful_job=true`
        }
        default: {
          url = `/jobs/${jobID}/posted?successful_job=true`
        }
      }
      document.location.href = url 
    },
  	
    render: function() {
	    return (
        <div className="modal-body job-form-body">
          <h3>Redirecting, Please wait...
            <div className="spinner-icon text-center" style={{marginLeft: '15px'}}/>
          </h3>
        </div>
      )
    }
  })
}

export default SubmitConsumerLead
