import React from 'react'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import Location from 'common/components/job_wizard/steps/Location'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import coreUtils from 'common/src/utils/coreUtils'
import NavigationButtons from 'common/components/NavigationButtons'
import SelectCategory from 'job_wizard_mobile/components/steps/SelectCategory'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'

const SelectLocation = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true){
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    JobWizardEventEmitter.emit('job:wizard:advanceToStep', SelectCategory)
  },

  hasSuburb() {
    return data.suburb_id !== 0
  },

  component: React.createClass({
    render(){
      return (
        <div className="form-horizontal">
          <div className="form-group mb30">
            <div className="col-xs-12 visible-xs details-mobile">
              <h3 className="mt10">Where do you need the job done?</h3>
              <div className="font-14 text-copy-0 pb5">Enter Postcode or Suburb</div>
              <div className="input-group p5 border radius-6">
                <Location />
                <span className="input-group-btn">
                  <NavigationButtons
                    navButtonLayout='okButton'
                    onStepProgression={this.props.onStepProgression}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}
export default SelectLocation
