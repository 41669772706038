import React from 'react'
import coreUtils from 'common/src/utils/coreUtils'

export default class Banner extends React.Component {
  handleClick() {
    coreUtils.googleAnalytics({ hitType: 'event', eventCategory: 'job-modal-close', eventLabel: 'switch', eventAction: 'switch-to-sign-up' })
  }

  render() {
    return(
      <div className="modal-body border-top sign-up-banner radius-16-bottom">
        <div className="grey-box p10 radius-6">
          <h4 className="mb15 mt10">Are you a business owner?</h4>
          <a href={'/list-your-business?previous_url=' + encodeURIComponent(window.location.href)} onClick={this.handleClick}>
            View available leads here
            <i className="ficon-arrow-right-shaft pr10 fs14" />
          </a>
        </div>
      </div>
    )
  }
}
