import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import ErrorMessage from 'common/components/ErrorMessage'
import NavigationButtons from 'common/components/NavigationButtons'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import GetFirstName from 'job_wizard_mobile/components/steps/GetFirstName'
import EnterDescription from 'job_wizard_mobile/components/steps/EnterDescription'
import SelectCategory from 'job_wizard_mobile/components/steps/SelectCategory'
import SelectLocation from 'job_wizard_mobile/components/steps/SelectLocation'
import AddAttachment from 'job_wizard_mobile/components/steps/AddAttachment'
import BudgetPrice from 'job_wizard_mobile/components/steps/BudgetPrice'
import ChooseSchedule from 'job_wizard_mobile/components/steps/ChooseSchedule'
import ChooseBudget from 'job_wizard_mobile/components/steps/ChooseBudget'
import ChooseSiteVisit from 'job_wizard_mobile/components/steps/ChooseSiteVisit'
import SiteVisitTime from 'job_wizard_mobile/components/steps/SiteVisitTime'

const JobSummary = {
  displayName: 'JobSummary',
  validator: '',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    onStepProgression()
  },

  onLeftNavButtonClick(onStepProgression) {
    $('#job-modal').data('bootstrapValidator').removeField('site_visit')

    if (data.siteVisit === 'weekdays' || data.siteVisit === 'weekends') {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', SiteVisitTime)
    }
    else {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', ChooseSiteVisit)
    }
  },

  buttonLayout: function() {
    return 'upButton'
  },

  component: React.createClass({
    componentDidMount: function() {
      actions.updateBackToSubmit(false)
    },

    handleLinkClick: function(componentName) {
      actions.updateBackToSubmit(true)
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', componentName)
    },

    renderLink: function(linkLabel, componentName){
      return (
        <a href="#"
           className="text-blue pl4"
           onClick={() => this.handleLinkClick(componentName)}>{linkLabel}
           <i className="ficon-arrow-down pl4"/>
        </a>
      )
    },

    renderFirstName: function() {
      return ` ${window.App.currentUserName || data.firstName}`
    },

    renderSchedule: function() {
      let schedule = ''
      if (data.scheduleDate !== "") {
        schedule = data.scheduleDate
      }
      else {
        switch (data.schedule) {
          case 'this_week':
            schedule = 'This week'
            break;
          case 'next_couple_of_weeks':
            schedule = 'Next couple of weeks'
            break;
          case 'asap_urgent':
            schedule = 'Urgent'
            break;
          default:
            schedule = 'On a Particular Date'
        }
      }

      return this.renderLink(schedule, ChooseSchedule)
    },

    renderBudget: function() {
      let budget = ''
      if (data.budgetInMind === "no") {
        budget = "No"
      }
      else {
        budget = data.budgetPriceRange !== null ? this.renderPriceRange(data) : data.budgetSetPriceRange
      }

      return this.renderLink(budget, ChooseBudget)
    },

    renderPriceRange: function(data) {
      let range = ''
      if (data.minBudget == '5001' && data.maxBudget == '5001') {
        range = 'More than $5000'
      } else {
        range = `$${data.minBudget} - $${data.maxBudget}`
      }

      return range
    },

    renderSiteVisit: function() {
      let siteVisit = ''
      if (data.siteVisit === "no_site_visit_required") {
        siteVisit = "no site visit"
      }
      else {
        siteVisit = data.siteVisit
      }

      return this.renderLink(siteVisit, ChooseSiteVisit)
    },

    render() {
      return (
        <div>
          <ErrorMessage />
          <h3 className="mb15 mt20">Submit your job</h3>
          <div className="form-group">
            <div className="input-group p5 details-mobile">
              <p>
                Hi
                {this.renderFirstName()},
              </p>
              <p>Let's see if we got this right:</p>
              <p>You need quotes for
                {this.renderLink(data.category, SelectCategory)}
                 in
                {this.renderLink(data.suburb, SelectLocation)}
              </p>
              <p>You said</p>
              <p>
                {this.renderLink(data.description, EnterDescription)}
              </p>
              <p>You attached
                <a href="#"
                   className="text-blue pl4"
                   onClick={() => this.handleLinkClick(AddAttachment)}>{data.fileNames.length} photo/s
                   <i className="ficon-arrow-down pl4"/>
                </a>
              </p>
              <p>Schedule?
                {this.renderSchedule()}
              </p>
              <p>Budget?
                {this.renderBudget()}
              </p>
              <p>Site Visit?
                {this.renderSiteVisit()}
              </p>
            </div>
          </div>
          <div className="pb24">
            By pressing 'Submit' you agree to receive lead alerts and marketing messages by email & SMS and to our <a className='no-wrap' href='https://www.serviceseeking.com.au/blog/terms-and-conditions/' target='_blank'>Terms & Conditions</a>
          </div>
          <NavigationButtons
            navButtonLayout='submitButton'
            onStepProgression={this.props.onStepProgression}
          />
        </div>
      )
    }
  })
}

export default JobSummary
