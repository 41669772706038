import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import UploadFile from 'job_wizard_mobile/components/steps/UploadFile'
import NavigationButtons from 'common/components/NavigationButtons'
import GetContact from 'job_wizard_mobile/components/steps/GetContact'
import CanCall from 'job_wizard_mobile/components/steps/CanCall'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'
import coreUtils from 'common/src/utils/coreUtils'

const ACCEPTED_FILE_TYPES = "image/png, image/jpeg, application/pdf"

const AddAttachment = {

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true){
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    if (window.App.currentUserId !== null && typeof window.App.currentUserId !== "undefined") {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', CanCall)
    }
    else {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', GetContact)
    }
  },

  component: React.createClass({
    getInitialState: function() {
      return {
        fileNames: [],
        fileErrors: ""
      }
    },

    handleFiles: function(e) {
      var result = processFiles()

      this.setState({fileErrors: ""})

      this.setState({
        fileNames: this.state.fileNames.concat(result['fileNames'])
      })

      if (result['rejectedFiles']){
        this.setState({fileErrors: "One or more files you selected are unsupported. Please select .pdf, .jpg, .jpeg, or .png image files."})
      }

      function processFiles() {
        let result = {}
        let fileNames = []

        // DOESN'T work in ios // for(let file of e.target.files) {
        // DOESN'T work in ios //   actions.uploadFiles(file)
        // DOESN'T work in ios //   fileNames.push(file.name)
        // DOESN'T work in ios // }
        // https://developer.mozilla.org/en/docs/Web/API/FileList

        var files = e.target.files;
        var fl = files.length;
        var file;

        for (var i = 0; i < fl; i++) {
            file = files[i];
            if (fileTypeIsSupported(file)) {
              actions.uploadFiles(file)
              actions.updateFileNames(file.name)
              fileNames.push(file.name)
            } else {
              result['rejectedFiles'] = true
            }
        }

        result['fileNames'] = fileNames
        return result
      }

      function fileTypeIsSupported(file) {
        var validExtensions  = ['jpg', 'jpeg', 'png', 'pdf']
        var fileExtension = file.name.split('.').pop().toLowerCase()
        
        return validExtensions.indexOf(fileExtension) > -1
      }
    },

    renderImageFileNames: function() {
      return (
        data.fileNames.map(function (name) {
          return (
            <span key={name}>
              <p>{name}</p>
            </span>
          )
        })
      )
    },

    render: function() {
      return(
        <div>
          <h3 className="mb5 mt10">Upload photos to help further explain your job.</h3>
          {
            data.fileNames.length == 0 ?
              <div>
                <div className='mt20 input-group'>
                  <span>
                    <UploadFile handleFiles={this.handleFiles} 
                      id={'job-description-fileupload'}
                      accept={ACCEPTED_FILE_TYPES}
                      buttonClass="btn btn-secondary btn-block"
                      labelTitle="Choose File"
                    />
                  </span>
                  <span className="input-group-btn pl20">
                    <NavigationButtons
                      navButtonLayout='skipButton'
                      onStepProgression={this.props.onStepProgression}
                    />
                  </span>
                </div>
                <div className='text-red'>
                  { this.state.fileErrors }
                </div>
              </div>
            :
              <div>
                <div className='mt20'>
                  <div className='mt5' style={{'overflowX': 'hidden', 'overflowY': 'scroll'}}>
                    { this.renderImageFileNames() }
                  </div>
                  <UploadFile handleFiles={this.handleFiles} 
                    id={'job-description-fileupload'}
                    accept={ACCEPTED_FILE_TYPES}
                    buttonClass="btn btn-block btn-default"
                    labelTitle="Add More"
                  />
                  <div className="pull-right pt10">
                    <NavigationButtons
                      navButtonLayout='okButton'
                      onStepProgression={this.props.onStepProgression}
                    />
                  </div>      
                </div>
                <div className='text-red'>
                  { this.state.fileErrors }
                </div>
              </div>
          }
        </div>
      )
    }
  })
}
export default AddAttachment