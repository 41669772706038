import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import coreUtils from 'common/src/utils/coreUtils'
import StepSequence from 'job_wizard_mobile/components/StepSequence'
import EnterDescription from 'job_wizard_mobile/components/steps/EnterDescription'
import SelectCategory from 'job_wizard_mobile/components/steps/SelectCategory'
import MobileChannelHandler from 'job_wizard_mobile/components/MobileChannelHandler'

const PostJobAgain = {
  displayName: 'PostJobAgain',

  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    onStepProgression()
  },

  component: React.createClass({
    componentWillUnmount: function() {
      $('#job-modal').find('.modal-footer').show()
    },

    moveToDescription: function () {
      var description = data.closureSurvey.job.description.split('Specific Job Details')[0]
      var subcategoryId = data.closureSurvey.job.subcategory_id
      var subcategory = data.closureSurvey.job.subcategory_name
      var suburbId = data.closureSurvey.job.suburb_id
      var suburb = data.closureSurvey.job.suburb

      actions.resetDataMobile()
      actions.updateCategory({ id: subcategoryId, value: subcategory })
      actions.updateSubCategory({id: subcategoryId, values: subcategory}, 'add')
      actions.updateLocation({ id: suburbId, value: suburb })
      actions.updateDescription(description)

      this.setState({currentStep: StepSequence.getSteps()[1]})
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', EnterDescription)
    },

    moveToFirstSlide: function () {
      actions.resetDataMobile()
      this.setState({currentStep: StepSequence.getSteps()[0]})
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', SelectCategory)
    },

    closeScreen: function() {
      let jobID = data.id
      let url = ''

      $('#job-modal').modal('hide')

      switch (data.referrer.cta) {
        case 'profile': {
          url = `/jobs/${jobID}/profilejob?successful_job=true`
        }
        case 'business_directory_card': {
          url = `/jobs/${jobID}/bcardjob?successful_job=true`
        }
        default: {
          url = `/jobs/${jobID}/posted?successful_job=true`
        }
      }

      document.location.href = url
    },

    render: function() {
      return (
        <div className="modal-body job-closure-form-body p0 pb32 pt20">
          {
            (data.closureSurvey.rating > 2) ?
              <div>
                <h3 className="pb16">Thanks for rating your experience.<br/>What else do you need done?</h3>
                <div className="row">
                  <div className="col-xs-12 pb24">
                    <a href="#" className="btn btn-block btn-clear btn-secondary radius-6" onClick={this.moveToFirstSlide}>
                      Find other great local businesses
                    </a>
                  </div>
                </div>
              </div>
            :
              <div>
                <h3>{`Thanks for rating ${data.closureSurvey.providerAccountName}.`}</h3>
                <div className="font-14 text-copy-0 pb24 pt20">
                  Do you still need someone for this job?
                </div>
                <div className="row">
                  <div className="col-xs-12 pb16">
                    <a href="#" className="btn btn-block btn-secondary radius-6" onClick={this.moveToDescription}>
                      Get new quotes
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 pb24">
                    <a href="#" className="btn btn-block btn-clear btn-secondary radius-6" onClick={this.moveToFirstSlide}>
                      Get quotes for a different job
                    </a>
                  </div>
                </div>
                <div className="font-14 text-copy-0 pb20">
                  Don't worry - We won't let {data.closureSurvey.providerAccountName} see or quote on any job you post in the future.
                </div>
                <div className="row">
                  <div className="col-xs-12 text-center">
                    <a href="#" className="bold text-copy-0" onClick={this.closeScreen}>
                      Close this screen
                    </a>
                  </div>
                </div>
              </div>
          }
        </div>
      )
    }
  })
}

export default PostJobAgain;
