import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import coreUtils from 'common/src/utils/coreUtils'
import SubmitFeedback from 'job_wizard_mobile/components/steps/SubmitFeedback'
import WhoDidTheJob from 'job_wizard_mobile/components/steps/WhoDidTheJob'
import RateExperience from 'job_wizard_mobile/components/steps/RateExperience'

const HasJobBeenDoneYet = {
  validator: '',
  component: React.createClass({
    getInitialState: function() {
      const outcome = data.closureSurvey.outcome
      return {
        trigger: 'job_modal',
        outcome: outcome
      }
    },
    componentDidMount: function(e) {
      actions.updateAdditionalQuestion(true)
      $('#outcome-radio-group').radioButtonGroup({activeRowClass: 'active'})
    },
    autoProgress: function(e) {
      const $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]
      const providerAccountId = data.closureSurvey.providerAccountId
      data.closureSurvey.outcome = $selectedItem.value

      JobWizardEventEmitter.emit('job:wizard:advanceToStep', WhoDidTheJob)
    },
    submitCancelledJob: function(e) {
      const $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]

      actions.updateClosureSurvey({
        job: data.closureSurvey.job,
        possibleBusinesses: data.closureSurvey.possibleBusinesses,
        outcome: $selectedItem.value,
        providerAccountId: null,
        providerAccountName: null,
        rating: null,
        comments: null,
        selectedReasons: []
      })

      JobWizardEventEmitter.emit('job:wizard:advanceToStep', SubmitFeedback)
    },
    render: function() {
      let description = data.closureSurvey.job.description.split('\n').map(i => { return <p style={{'word-break': 'break-all'}}>{i}</p> })
      return (
        <div>
          <h3>{`While you're here, can we ask for an update on your ${data.closureSurvey.job.suburb_record_name} ${data.closureSurvey.job.subcategory_name} job from ${data.closureSurvey.job.created_at}?`}</h3>
          <div className="mt10 mb20 bg-gray-2 radius-6 p16 border">
            {description}
          </div>
          <div className="form-group" onClick={this.handleOutcomeRadioClick}>
            <h3 className="mb15 mt10">Has your job been done yet?</h3>
            <div className="radio-button-box minor-bold" id="outcome-radio-group">
              <div className={"radio " + ((this.state.outcome === "job_completed") ? "active" : "")} onClick={this.autoProgress}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10 this-week">Yes</div>
                  <input type="radio" defaultChecked={(this.state.outcome === "job_completed") ? 'checked' : ''} id="job_completed" name="outcome" value="job_completed" className="form-control invisible"/>
                </div>
              </div>
              <div className={"radio " + ((this.state.outcome === "going_ahead") ? "active" : "")} onClick={this.autoProgress}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Not yet!</div>
                  <input type="radio"  defaultChecked={(this.state.outcome === "going_ahead") ? 'checked' : ''} id="going_ahead" name="outcome" value="going_ahead" className="form-control invisible"/>
                </div>
              </div>
              { (this.state.trigger !== "hire_button" && this.state.trigger !== "leave_feedback_button") &&
                <div className={"radio " + ((this.state.outcome === "not_going_ahead") ? "active" : "")} onClick={this.submitCancelledJob}>
                  <div className="row">
                    <div className="col-xs-10 col-sm-10 asap-urgent">No, I cancelled the job</div>
                    <input type="radio" defaultChecked={(this.state.outcome === "not_going_ahead") ? 'checked' : ''} id="not_going_ahead" name="outcome" value="not_going_ahead" className="form-control invisible"/>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )
    }
  })
}

export default HasJobBeenDoneYet
