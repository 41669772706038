import React from 'react'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import RateExperience from 'job_wizard_mobile/components/steps/RateExperience'
import SubmitFeedback from 'job_wizard_mobile/components/steps/SubmitFeedback'
import HasJobBeenDoneYet from 'job_wizard_mobile/components/steps/HasJobBeenDoneYet'

const WhoDidTheJob = {
  validator: '',
  component: React.createClass({
    componentDidMount: function() {
      const closureData = data.closureSurvey

      $('#business-radio-group').radioButtonGroup({activeRowClass: 'active'})
    },

    previous: function() {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', HasJobBeenDoneYet)
    },

    autoProgressSelectedBusiness: function(e, businessName) {
      const outcome = data.closureSurvey.outcome
      const $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]

      actions.updateClosureSurvey({
        job: data.closureSurvey.job,
        possibleBusinesses: data.closureSurvey.possibleBusinesses,
        outcome: outcome,
        providerAccountId: $selectedItem.value,
        providerAccountName: businessName,
        rating: null,
        comments: null,
        selectedReasons: [],
        value: data.closureSurvey.value
      })

      if (outcome === "going_ahead") {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', SubmitFeedback)
      }
      else {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', RateExperience)
      }
    },

    autoProgressOtherOptions: function(e) {
      const $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]
    
      actions.updateClosureSurvey({
        job: data.closureSurvey.job,
        possibleBusinesses: data.closureSurvey.possibleBusinesses,
        outcome: $selectedItem.value,
        providerAccountId: null,
        providerAccountName:  null,
        rating: null,
        comments: null,
        selectedReasons: [],
        value: data.closureSurvey.value
      })

      JobWizardEventEmitter.emit('job:wizard:advanceToStep', SubmitFeedback)
    },

    render: function() {
      return (
        <div>
          <h3 className="mb15 mt20">{data.closureSurvey.outcome === "going_ahead" ? "Have you chosen a business to do the work?" : "Who did the job?"}</h3>
          <div className="form-group">
            <div className="radio-button-box minor-bold" id="business-radio-group">
              { data.closureSurvey.possibleBusinesses.map((business) => {
                  return (
                    <div key={business.id} className="radio" onClick={(e) => this.autoProgressSelectedBusiness(e, business.business_name)}>
                      <div className="row">
                        <div className="col-xs-10 col-sm-10 bold">{business.user_name} from {business.business_name}</div>
                        <div className="col-xs-10 col-sm-10 font-12">{business.mobile_phone}</div>
                        <input type="radio" id={business.id} name="provider_account_id" value={business.id} className="form-control invisible"/>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            { data.closureSurvey.outcome === "going_ahead" &&
              <div className="radio-button-box minor-bold pt30" id="outcome-radio-group">
                <div className="radio" onClick={this.autoProgressOtherOptions}>
                  <div className="row">
                    <div className="col-xs-10 col-sm-10 bold">I'm still deciding who to hire</div>
                    <input type="radio" id="going_ahead" name="outcome" value="going_ahead" className="form-control invisible"/>
                  </div>
                </div>
              </div>
            }
            <div className="radio-button-box minor-bold pt30" id="outcome-radio-group">
              <div className="radio" onClick={this.autoProgressOtherOptions}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10 bold">A business from outside ServiceSeeking.com.au</div>
                  <input type="radio" id="job_completed_externally" name="outcome" value="job_completed_externally" className="form-control invisible"/>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-sm-6 col-xs-6 pl18'>
              <a href="#" className="btn btn-block btn-clear" onClick={this.previous}>
                <i className='ficon-arrow-left-shaft pr10 fs14' />
                Back
              </a>
            </div>
          </div>
        </div>
      )
    }
  })
}


export default WhoDidTheJob;
