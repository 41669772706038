import React from 'react'
import 'common/components/job_wizard/utils/custom_radio_boxes'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'
import data from 'common/components/job_wizard/data/data'
import actions from 'common/components/job_wizard/data/actions'
import JobWizardEventEmitter from 'common/components/job_wizard/JobWizardEventEmitter'
import NavigationButtons from 'common/components/NavigationButtons'
import AddAttachment from 'job_wizard_mobile/components/steps/AddAttachment'
import GetContactPreference from 'job_wizard_mobile/components/steps/GetContactPreference'
import ChooseSiteVisit from 'job_wizard_mobile/components/steps/ChooseSiteVisit'
import JobSummary from 'job_wizard_mobile/components/steps/JobSummary'
import coreUtils from 'common/src/utils/coreUtils'

const ChooseSchedule = {
  onSubmit: function(onStepProgression, onRequest, onRequestCompletion, onStepRetreat) {
    if (data.backToSubmit === true){
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
    }
    else {
      onStepProgression()
    }
  },

  onLeftNavButtonClick(onStepProgression) {
    $('.choose-schedule').addClass('hidden')
    $('#job-modal').data('bootstrapValidator').removeField('schedule').removeField('scheduleDate')

    if (data.quoteEstimate !== null) {
      if (data.canCall === true && data.quoteEstimate >= 12) { // QuotesEstimator::SUPPRESSANT_MINIMUM_QUOTES
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', GetContactPreference)
      }
      else {
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', AddAttachment)
      }
    }
    else {
      JobWizardEventEmitter.emit('job:wizard:advanceToStep', AddAttachment)
    }
  },

  component: React.createClass({
    getInitialState: function() {
      const job = data;

      return {
        schedule:     job.schedule,
        scheduleDate: job.scheduleDate
      }
    },

    componentDidMount: function() {
      $('#job-modal').bootstrapValidator({ /* */ })
      this.validator = $('#job-modal').data('bootstrapValidator')

      $('#schedule-radio-group').radioButtonGroup({activeRowClass: 'active'})

      $('#schedule-date').datepicker({
        container: '#job-modal',
        format: 'dd/mm/yyyy',
        disableTouchKeyboard: true,
        todayHighlight: true,
        orientation: 'top left',
        startDate: '+1d'
      }).on('changeDate', function(event) {
        $(this).datepicker('hide')
        $('#job-modal').data('bootstrapValidator').$form.data('bootstrapValidator').revalidateField("scheduleDate") //revalidating removes error message
        actions.updateScheduleDate(event.currentTarget.value)
      })

      this.setupValidation()
    },

    setupValidation: function() {
      const validator = this.validator;

      $(function() { // doc ready
        validator.addField(
          'schedule',
          {
            validators: {
              notEmpty: {
                message: 'Please select a schedule for when you want the job done'
              }
            }
          }
        )
        validator.addField(
          'scheduleDate',
          {
            validators: {
              notEmpty: {
                message: 'Please select a date for when you want the job done'
              }
            }
          }
        )
      }) // end doc ready
    },

    autoProgress: function(e) {
      const $selectedItem = $(e.currentTarget).find('[type="radio"]')[0]
      actions.updateSchedule($selectedItem.value)
      hideScheduleDatePanel()

      if (data.backToSubmit === true){
        JobWizardEventEmitter.emit('job:wizard:advanceToStep', JobSummary)
      }
      else {
        this.props.onStepProgression()
      }

      function hideScheduleDatePanel() {
        $('#schedule-panel').addClass('fade hidden')
      }
    },

    handleDateClick: function(e) {
      actions.updateSchedule("on_a_particular_date")
      actions.updateScheduleDate($('#schedule-date').val())
    },

    handleScheduleRadioClick: function() {
      this.validator.$form.data('bootstrapValidator').revalidateField("schedule") //revalidating removes error message
    },

    render: function() {
      const radioButtonVisible = "col-xs-2 col-sm-2 circle"
      let radioButtonHidden = `${radioButtonVisible} hidden`

      return (
        <div>
          <div className="form-group choose-schedule" onClick={this.handleScheduleRadioClick}>
            <h3 className="mb15 mt10">When do you need the job done?</h3>
            <div className="radio-button-box minor-bold" id="schedule-radio-group">
              <div className="radio" onClick={this.autoProgress}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10 this-week">This week</div>
                  <input type="radio" id="this_week" name="schedule" value="this_week" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio" onClick={this.autoProgress}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10">Next couple of weeks</div>
                  <input type="radio" id="next_couple_of_weeks" name="schedule" value="next_couple_of_weeks" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio" onClick={this.autoProgress}>
                <div className="row">
                  <div className="col-xs-10 col-sm-10 asap-urgent">Urgently</div>
                  <input type="radio" id="asap_urgent" name="schedule" value="asap_urgent" className="form-control invisible"/>
                </div>
              </div>
              <div className="radio" href="#schedule-panel" role="tab" data-toggle="tab" onClick={this.handleDateClick} >
                <div className="row">
                  <div className='col-xs-10 col-sm-10'>On a Particular Date</div>
                  <input type="radio" name="schedule" value="on_a_particular_date" className="form-control invisible" />
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content">
            <div id="schedule-panel" className="tab-pane form-group" role="tabpanel" >
              <h3 className="mb15 mt30">Best date for work</h3>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-addon bg-white"><i className="ficon-calendar"></i></div>
                  <input id="schedule-date" name="scheduleDate" ref="schedule-date" type="textbox" className="form-control" placeholder="What Day?" defaultValue={this.state.scheduleDate} />
                </div>
                <span className="pull-right pt10">
                  <NavigationButtons
                    navButtonLayout='okButton'
                    onStepProgression={this.props.onStepProgression}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
}

export default ChooseSchedule
