let currentPage = $('div.wrapper');
currentPage
    .on('change', '#js-compare-to-others-dropdown', function() {
        if (this.value === "hipages") {
        	$('.serviceseeking-qualities').addHidden();
        	$('.hipages-qualities').rmHidden();
            $('.airtasker-qualities').addHidden();
        }
        else if (this.value === "airtasker") {
        	$('.serviceseeking-qualities').addHidden();
            $('.hipages-qualities').addHidden();
            $('.airtasker-qualities').rmHidden();
        }
        else {
        	$('.serviceseeking-qualities').rmHidden();
            $('.hipages-qualities').addHidden();
            $('.airtasker-qualities').addHidden();
        }
    });