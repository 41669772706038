'use strict';

import React from 'react'

import * as timeago from 'timeago.js'

class ActivityFeed extends React.Component {
  componentDidMount() {
    // Render live timestamps on initial load
    timeago.render($('.timeago'))

    this.props.activityFeeds.slice(this.props.perPage + 1, this.props.activityFeeds.length).map((content, index) => {
      // Prepend each item with x seconds interval
      setTimeout(function(){
        let dateToday = new Date()
        let justNow = dateToday.setSeconds(dateToday.getSeconds() - 1)

        $('.feed-container').prepend(`<div class='pt24 pb24 border-bottom'>
          <span class='font-14'>${content}</span>
          <span class='font-14 text-copy-0 pull-right hidden-xs timeago-new' datetime="${justNow}"></span>
          <span class='font-14 text-copy-0 visible-xs timeago-new' datetime="${justNow}"></span>
          </div>`)

        // Render live timestamps for each new item
        timeago.render($('.timeago-new'))
      }, index * this.props.interval)
    })
  }

  activityFeedsByMins() {
    // Display first x number of items on initial load with assigned random minutes
    let results = this.props.activityFeeds.slice(0, this.props.perPage).map((content, index) => {
      let dateToday = new Date()
      let minutes = this.props.randomMinutes[Math.floor(Math.random() * this.props.randomMinutes.length)]

      return ({content: content, timeAgo: dateToday.setMinutes(dateToday.getMinutes() - minutes), minutes: minutes})
    })

    return results
  }

  render() {
    return(
      <div className='container'>
        <div className='row pt40'>
          <div className='col-xs-12'>
            <div className='font-24 bold-500 pb24'>Latest Activity</div>
            <div className='feed-container'>
              {
                // Sort each activity by ASC order
                this.activityFeedsByMins().sort(function(a, b) {return a.minutes - b.minutes })
                                          .map((feed, index) => {
                  return(
                    <div className='pt24 pb24 border-bottom' key={feed.content}>
                      <span dangerouslySetInnerHTML={{ __html: feed.content }} ></span>
                      <span className='font-14 text-copy-0 pull-right hidden-xs timeago' dateTime={feed.timeAgo}></span>
                      <span className='font-14 text-copy-0 visible-xs timeago' dateTime={feed.timeAgo}></span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityFeed
